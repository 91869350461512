var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-80 p-2 bg-gray-200 dark:bg-gray-900"},[_c('div',{staticClass:"mt-1 flex space-x-4 absolute left-1/3 top-32"},[_c('button',{class:[
        'px-4 py-2 rounded-lg',
        _vm.activeTable === 'secondary'
          ? 'bg-red-600 text-white'
          : 'bg-gray-200 text-gray-700 hover:bg-gray-300',
      ],on:{"click":function($event){_vm.activeTable = 'secondary'}}},[_vm._v(" Devam Edenler ")]),_c('button',{class:[
        'px-4 py-2 rounded-lg',
        _vm.activeTable === 'deviation'
          ? 'bg-red-600 text-white'
          : 'bg-gray-200 text-gray-700 hover:bg-gray-300',
      ],on:{"click":function($event){_vm.activeTable = 'deviation'}}},[_vm._v(" Tamamlananlar ")])]),(_vm.activeTable === 'deviation')?_c('RouteDeviationTable'):_c('SecondaryTable')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }