<template>
  <tr colspan="3">
    <td class="table-td border-r border-opacity-20">#</td>
    <td class="table-td border-r border-opacity-20">
      <vehicleSelect
        v-model="vehicle"
        :optList="getFilterVehicle"
        :load="vehicleLoad"
        :sorted="activeList != null || activeList <= vehicleList ? true : false"
        :products="products"
        :customers="customers"
        :order="order"
        :isBulk="activeList != null || activeList <= vehicleList ? true : false"
        :selectedVehicles="selectedVehicles"
      />
    </td>
    <td class="table-td border-r border-opacity-20">
      {{ vehicle?.address || "" }}
    </td>
    <td class="table-td border-r border-opacity-20">
      {{ vehicle?.washPointName || "" }}
    </td>
    <td class="table-td border-r border-opacity-20">
      <span v-if="vehicle?.washCost >= 0">{{ vehicle.washCost }}</span>
    </td>
    <td class="table-td border-r border-opacity-20">
      <span v-if="vehicle?.recomendationTotalCost >= 0">
        {{ vehicle.recomendationTotalCost }}
      </span>
    </td>
    <td class="table-td border-r border-opacity-20">
      <span v-if="vehicle?.allCost !== undefined && vehicle?.allCost !== null">
        {{ vehicle.allCost.toFixed(2) }}
      </span>
    </td>
    <td class="text-center">
      <button
        type="button"
        class="process-btn"
        @click="addItem"
        :disabled="!vehicle"
      >
        <i class="fas fa-plus mr-1.5"></i> Ekle
      </button>
    </td>
  </tr>
</template>

<script>
import { calculator } from "../../../utils/cost";
import { calculateDistancePriority } from "../../../utils/recomendation";
import vehicleSelect from "../../../views/operations/orders/components/vehicle-select.vue";

export default {
  name: "new-vehicle-row",

  components: {
    vehicleSelect,
  },

  props: [
    "order",
    "products",
    "customers",
    "vehicleList",
    "activeList",
    "selectedVehicles",
  ],

  data() {
    return {
      vehicle: null,
      vehicleLoad: false,
      calculationInProgress: false,
    };
  },

  computed: {
    getFilterVehicle() {
      return this.vehicleList?.map((item) => ({
        ...item,
        name: item.vehicle,
        isRent: item?.haveType,
      }));
    },
  },

  methods: {
    async calculateCost() {
      if (!this.vehicle || this.calculationInProgress) return null;

      this.calculationInProgress = true;

      const distancePriority = calculateDistancePriority(
        this.order,
        this.vehicle
      );
      const distance = parseFloat(distancePriority).toFixed(2);

      try {
        const cost = await calculator(
          this.$store.state.userData.token,
          1,
          1,
          this.vehicle,
          this.order,
          distance,
          0,
          true,
          true,
          1,
          distance / 50
        );

        return {
          ...this.vehicle,
          washPointName: cost.washPointName,
          recomendationTotalCost: Number(cost.total),
          washCost: Number(cost.totalWashPriceCost),
          allCost: Number(cost.total) + Number(cost.totalWashPriceCost),
        };
      } catch (error) {
        console.error(
          `Failed to calculate cost for vehicle ${
            this.vehicle?.id || "unknown"
          }:`,
          error
        );
        return null;
      } finally {
        this.calculationInProgress = false;
      }
    },

    async addItem() {
      if (!this.vehicle) return;

      try {
        this.vehicleLoad = true;

        // Calculate cost if not already calculated
        if (!this.vehicle.recomendationTotalCost) {
          const costData = await this.calculateCost();
          if (costData) {
            this.vehicle = { ...this.vehicle, ...costData };
          }
        }

        this.$emit("addItem", this.vehicle);
        this.vehicle = null;
      } catch (error) {
        console.error("Error adding vehicle:", error);
      } finally {
        this.vehicleLoad = false;
      }
    },
  },

  watch: {
    vehicle: {
      async handler(newValue, oldValue) {
        // Only calculate if we have a new vehicle selected and it doesn't already have costs calculated
        if (
          newValue &&
          (!newValue.recomendationTotalCost || !newValue.washCost) &&
          JSON.stringify(newValue) !== JSON.stringify(oldValue)
        ) {
          const costData = await this.calculateCost();
          if (costData) {
            // Use Object.assign to avoid triggering the watcher again
            this.vehicle = { ...this.vehicle, ...costData };
          }
        }
      },
      deep: true,
    },
  },
};
</script>
