<template>
  <modal
    name="route-report-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="true"
  >
    <!-- Modal Header -->
    <div
      class="pb-2 pt-4 pr-4 pl-4 border-b mb-4 border-gray-200 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 2xl:h-24 flex items-center justify-between"
    >
      <h4 class="text-xl font-semibold">Rota Raporu</h4>
      <button
        type="button"
        class="px-3 text-lg"
        @click="$modal.hide('route-report-modal')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Loading Indicator -->
    <div v-if="isLoading" class="w-full h-96 flex items-center justify-center">
      <i class="fas fa-spinner fa-3x text-gray-400 fa-spin"></i>
    </div>

    <!-- Modal Content -->
    <div
      v-if="detail.routeId && !isLoading"
      class="pb-4 px-4 pt-2 items-start justify-between 2xl:mb-20 mb-8"
    >
      <!-- Recommendation Details -->
      <div v-if="detail" class="w-full pb-4 space-y-2">
        <div class="flex items-center justify-between">
          <label class="block font-bold text-lg mb-2">SİPARİŞ DETAYLARI</label>
          <div class="flex items-center space-x-4">
            <div class="flex items-center">
              <div class="w-4 h-4 bg-red-500 mr-2"></div>
              <span>Gerçekleşen Rota</span>
            </div>
            <div class="flex items-center">
              <div class="w-4 h-4 bg-blue-500 mr-2"></div>
              <span>Yapılması Beklenen Rota</span>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-between items-start space-x-4">
          <div class="w-1/2">
            <previewDetail label="Güzergah ID" :value="detail.routeId || '-'" />

            <previewDetail
              label="Yapılması Gereken Kilometre"
              :value="detail.expectedKm"
            />
            <previewDetail label="Yapılan Kilometre" :value="detail.actualKm" />
            <previewDetail
              label="Seferi Beklenen Kilometre"
              :value="detail.seferiKm"
            />
          </div>
          <div class="w-1/2">
            <previewDetail label="Araç Plakası" :value="detail.vehicle" />
            <previewDetail label="Sefer Sayısı" :value="detail.routeCount" />
             <previewDetail
              label="Doluma Giriş Tarihi"
               :value="formattedEndDate(detail.entryFillingPointDate)"
            />
            <previewDetail
              label="Teslime Giriş Tarihi"
               :value="formattedEndDate(detail.entryDeliveryPointDate)"
            />
          </div>
        </div>
      </div>
      <!-- Map Section -->
      <div class="w-full">
        <routeReportMap
          :polylineOne="detail.actualPolyline"
          :polylineTwo="detail.seferiPolyline"
          :item="detail"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment";

// Local Components
import previewDetail from "../../../operations/planning/components/preview-modal/previewDetail.vue";
import routeReportMap from "./routeReportMap.vue";

export default {
  name: "route-report-modal",
  props: ["detail"],
  components: {
    routeReportMap,
    previewDetail,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    formattedEndDate(val) {
      return val ? moment(val).format("DD/MM/YYYY HH:MM") : "-";
    },
  },
};
</script>
