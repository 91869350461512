<template>
  <div class="bg-gray-200 dark:bg-gray-900 p-2">

    <addModal @refresh="getAll()" />
    <editModal @refresh="getAll()" :rowId="selected.id" />
    <productAddModal :defaultName="productName" @refresh="getAll(true, true)" />
    <vehicleModal :detail="selected" />

    <VueContext ref="menu">
        <li><a href="#" @click.prevent="customPlan(1)">Kiralık Araç Öner</a></li>
        <li><a href="#" @click.prevent="customPlan(2)">Özmal Araç Öner</a></li>
    </VueContext>

    <div class="p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <div :class="isFullScreen && 'fixed top-0 w-full z-50   left-0 h-full  bg-white'">
        <div class="w-full mt-2">
          <div class="md:flex items-center justify-between w-full">
            <div class="w-full xl:w-3/12">
              <div class="md:flex items-center mt-2">
                <button v-if="!planningLoad" @click="() => applyItem()" :disabled="load || selectedList.length == 0"
                  :class="{
                    'ml-2 ': isFullScreen,
                    'bg-opacity-50': load || selectedList.length === 0,
                  }" class="py-2 px-4 bg-black rounded text-center text-white text-sm">
                  <i :class="!load ? 'fas fa-check' : 'fas fa-spinner fa-spin'"></i>
                  {{ !load ? "Siparişleri Kaydet" : "Kayıt Ediliyor" }}

                </button>
              </div>
            </div>
            <div class="w-6/12 flex items-center transition-all duration-500 justify-center">
              <button @click="activeState = 1" class="py-2 w-40 rounded border-b-4 bg-white shadow mr-2 text-sm"
                :class="activeState == 1 && ' border-red-700 text-red-700'">
                Planlanmayanlar ({{ successCount - selectedList.length }})
              </button>
              <button @click="(activeState = 2, search = '')"
                :class="activeState == 2 && ' border-red-700 text-red-700'"
                class="w-40 py-2 rounded bg-white shadow mr-2 text-sm border-b-4">
                Planlananlar ({{ selectedList.length }})
              </button>
              <button @click="activeState = 3" :class="activeState == 3 && ' border-red-700  text-red-700'"
                class="w-40 py-2 rounded bg-white shadow text-sm border-b-4">
                Sorunlu ({{ errorCount }})
              </button>
            </div>
            <div class="w-3/12" :class="isFullScreen && 'mr-2'">
              <searchInput :isFull="true" v-model="search" @searcing="() => getAll()" />
            </div>
          </div>

          <div :class="!isFullScreen
            ? 'shadow table-height-value  overflow-y-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4'
            : 'w-full overflow-y-scroll  h-screen pb-28 mt-4 '
            ">
            <div class="relative">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
                  <tr>
                    <th v-if="isPlanning" scope="col" class="table-th text-center">
                      <input v-if="false" disabled type="checkbox" v-model="selectedAll" />
                    </th>
                    <th scope="col" class="table-th text-left border-r border-opacity-20"
                      style="min-width: 120px !important">
                      #
                    </th>
                    <th scope="col" class="table-th text-left border-r border-opacity-20"
                      style="min-width: 215px !important">
                      Dolum
                    </th>
                    <th scope="col" class="table-th text-left border-r border-opacity-20"
                      style="min-width: 218px !important">
                      Teslim
                    </th>

                    <th scope="col" class="table-th text-left border-r border-opacity-20"
                      style="min-width: 180px !important">
                      Müşteri
                    </th>
                    <th scope="col" class="table-th text-left border-r border-opacity-20"
                      style="min-width: 110px !important; z-index: 99 !important">
                      Ürün
                      <i class="fas fa-arrow-down"></i>
                    </th>
                    <th scope="col" class="table-th text-left border-r border-opacity-20 z-30"
                      style="min-width: 70px !important">
                      Miktar
                      <tableResizeBtn v-model="isFullScreen" v-if="activeState == 3" />
                    </th>

                    <th scope="col" class="table-th text-left z-20 border-r border-opacity-20"
                      style="min-width: 120px !important" v-if="activeState === 2">
                      Yıkama Noktası
                    </th>

                    <th scope="col" class="table-th text-left z-20 border-r border-opacity-20"
                      style="min-width: 120px !important" v-if="activeState === 2">
                      Yıkama Maliyeti
                    </th>

                    <th scope="col" class="table-th text-left z-20 border-r border-opacity-20"
                      style="min-width: 120px !important" v-if="activeState === 2">
                      Sefer Maliyeti
                    </th>

                    <th scope="col" class="table-th text-left z-20 border-r border-opacity-20"
                      style="min-width: 120px !important" v-if="activeState === 2">
                      Toplam Maliyet
                    </th>

                    <th scope="col" class="table-th text-left z-20 border-r border-opacity-20"
                      style="min-width: 200px !important" v-if="activeState < 3">
                      Araç
                      <tableResizeBtn v-model="isFullScreen" v-if="activeState == 1" />
                    </th>
                    <th scope="col" class="table-th text-left z-20 border-r border-opacity-20"
                      style="min-width: 200px !important" v-if="activeState == 2">
                      Sürücü
                    </th>
                    <th scope="col" class="table-th text-left z-20 border-r border-opacity-20"
                      style="min-width: 100px !important" v-if="activeState == 2">
                      #
                      <tableResizeBtn v-model="isFullScreen" v-if="activeState == 2" />
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
                  <tr v-for="(item, index) in getOrderList" :key="index"
                  @contextmenu.prevent="event=>openContextMenu(event,item)"
                  >
                    <td v-if="isPlanning" @click="selectItem(item)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none z-40">
                      <i v-if="activeState == 1" class="bg-white rounded block h-4 w-4"></i>
                      <i v-else-if="activeState == 2" class="fas fa-check bg-blue-500 text-white rounded"
                        style="padding: 2px"></i>
                    </td>

                    <td @click="selectItem(item)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none">
                      <span class="text-white text-opacity-80">#
                        {{ item.orderNo }}
                      </span>

                      <p class="text-xs" :title="item.orderName">{{ truncateText(item.orderName, 13) }}</p>

                    </td>

                    <td @click="selectItem(item)" class="table-td border-r border-opacity-20 cursor-pointer">
                      
                      <p class="text-white" style="font-size: 10px">
                        <p class="text-xs" :title="item.receivedName">
                          {{ truncateText(item.receivedName, 24) }}
                        </p>
                      </p>

                      <div class="flex items-center justify-between">
                        <p class="text-white text-opacity-75" style="font-size: 9px">
                          {{ getLastThreeWords(item.outpointAdress) }}
                        </p>
                        <p class="text-white text-opacity-50" style="font-size: 8px">
                          {{ item.orderDate | getDateFormat }}
                        </p>
                      </div>
                    </td>
                    <td @click="selectItem(item)" class="table-td border-r border-opacity-20 cursor-pointer">
                      <p class="text-white" style="font-size: 10px" :title="item.deliveredName">
                        {{ truncateText(item.deliveredName, 22) }}
                      </p>
                      <div class="flex items-center justify-between">
                        <p class="text-white text-opacity-75" style="font-size: 8px">
                          {{ getLastThreeWords(item.targetAdress) }}
                        </p>

                        <p class="text-white opacity-50" style="font-size: 8px">
                          {{ item.deliveryDate | getDeliveryDateFormat }}
                          {{ item.deliveryTime }}
                        </p>
                      </div>
                    </td>

                    <td @click="selectItem(item)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none">
                      <p :title="item.customer"> {{ truncateText(item.customer, 22) }}</p>
                    </td>
                    <td @click="selectItem(item)" class="table-td border-r border-opacity-20 cursor-pointer select-none"
                      :title="!isProductValid(item.productType) && 'Ürün Sistemde Bulunamadı'
                        " :class="!isProductValid(item.productType) && 'bg-red-700  animate-pulse'
                          ">
                      {{ item.productType }}
                    </td>
                    <td @click="selectItem(item)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none">
                      <p class="text-xs">{{ item.tonnage }} {{ item.unitType }}</p>
                    </td>

                    <td
                      v-if="!item.isMultiple && activeState === 2"
                      @click="selectItem(item)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none"
                    >
                      <p class="text-xs">{{ item.washPointName || "-" }}</p>
                    </td>

                    <td
                      v-if="!item.isMultiple && activeState === 2"
                      @click="selectItem(item)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none"
                    >
                      <p class="text-xs">{{ item.washCost }}</p>
                    </td>

                    <td
                      v-if="!item.isMultiple && activeState === 2"
                      @click="selectItem(item)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none"
                    >
                      <p class="text-xs">{{ item.recomendationTotalCost }}</p>
                    </td>

                    <td
                      v-if="!item.isMultiple && activeState === 2"
                      @click="selectItem(item)"
                      class="table-td border-r border-opacity-20 cursor-pointer select-none"
                    >
                      <p class="text-xs">{{ Number(item.allCost).toFixed(2) }}</p>
                    </td>

                    <td v-if="activeState < 3" class="table-td w-28 border-r border-opacity-20 text-center font- "
                      :colspan="item.isMultiple && 6">

                      <div 
                        v-if="!item.isMultiple && activeState == 1 && (item.unitType === 'TON' ? item.tonnage < 31 : item.tonnage < 2 )"
                        class="px-4 py-1 rounded w-full text-opacity-80 text-white">
                        SPOT SİPARİŞ
                      </div>

                      <vehicleSelect 
                        v-else-if="!item.isMultiple && activeState == 2" 
                        :value="item.vehicle"
                        :optList="getFilterVehicle"
                        @changeName="(val) => changeVehicle(val, item)"
                        @changeDriverName="(driver) => changeDriver(driver, item)"
                        :customers="customers"
                        :order="item"
                        :sorted="true"
                        :products="products"
                        @changeDorse="(val) => (item.dorse = val)"
                      />

                      <button v-else @click="showConfirm()" class="px-4 py-1 rounded w-full text-opacity-80 text-white">
                        TOPLU SİPARİŞ
                      </button>

                    </td>
                    <td v-if="activeState == 2 && !item.isMultiple" class="table-td border-r border-opacity-20 text-center">
                      <personnelSelect :titleNone="true" :hideContainer="true" :value="item.vehicleName"
                        @changeName="val => item.driver = val" @changeTC="val => item.driverTC = val" :isCache="true"
                        class="py-2"/>
                    </td>
                    <td class="table-td text-center" v-if="activeState == 2">
                      <button class="process-btn mr-2" v-if="item.tonnage <= 30" @click="seledtedOrder(item)">
                        <i class="fas fa-search"></i>
                      </button>
                      <button class="process-btn" @click="selectItem(item)">
                        <i class="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                   <tableLoader :load="load" :length="getOrderList.length" :colspan="activeState==2?13:11" />
                  <td colspan="8" v-if="planningLoad" class="text-center py-4 text-white">
                    <i class="fas fa-spinner fa-spin"></i> Planlanıyor
                  </td>
                </tbody>
              </table>
            </div>
          </div>
          <div :class="!isFullScreen
            ? 'flex justify-between items-center'
            : 'absolute bottom-0 w-full bg-white flex justify-between items-center pr-2'
            ">
            <paginationVue :pageCount="pageCount" :currentPage="currentPage" :totalRowCount="activeState == 2 ?
              selectedList.length : List.length
              " @changePage="(r) => (currentPage = r)" />
            <span class="w-6/12 text-right block">Toplam {{ totalCount }} adet kayıt listelendi</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Npm
import similarity from "similarity";
import moment from "moment";

// Global Components
import personnelSelect from "@/components/devItem/personnelInput.vue";
import searchInput from "@/components/general/search-input.vue";
import vehicleModal from "@/components/modals/vehicle.detail.modal/index.vue";
import tableResizeBtn from "@/components/general/table.resize.btn.vue";
import tableLoader from "@/components/general/table-loader.vue";
import paginationVue from "@/components/general/pagination.vue";

// Local Components
import addModal from "./components/add.modal.vue";
import editModal from "./components/edit.modal.vue";
import productAddModal from "../../control-panel/products/components/add.modal.vue";
import vehicleSelect from "./components/vehicle-select.vue";

// Networking
import axios from "axios";
import { orders } from "@/networking/urlmanager";

import VueContext from 'vue-context';

// utils
import {
  isWashStatus,
  isVehicleSuitableForOrderDetail,
  recommendSingleVehicleForOrder,
  calculateCustomerPriority,
  calculateDistancePriority,
} from "@/utils/recomendation";
import { getLocationAll, getCustomers, getProducts } from "@/utils/detailData";
import { calculator } from "@/utils/cost";

export default {
  name: "orders-module",
  components: {
    // DiffInput,
    addModal,
    editModal,
    tableLoader,
    paginationVue,
    vehicleSelect,
    personnelSelect,
    searchInput,
    productAddModal,
    vehicleModal,
    tableResizeBtn,
    VueContext
  },
  data() {
    return {
      // dedicatedVehicles: [],
      i: 1, // Kullanılmıyor Alparslan ???
      planningLoad: false, // gereksiz state ?
      currentPage: 0,
      pageCount: 1,
      totalCount: 0,
      isFullScreen: false,
      activeState: 1,
      List: [],
      selectedList: [],
      selectedAll: false,
      errorCount: 0,
      currentHaveType: null,
      successCount: 0,
      vehicleList: [],
      inProgress: false,
      selectedVehicles: [],
      products: [],
      customers: [],
      load: false,
      search: "",
      isPlanning: false,
      selected: { id: "", plaque: "", vehicleId: "" },
      productName: "",
      tripTonnage: 26,

      selectedContextItem:null,
    };
  },
  methods: {
    openContextMenu(event,item) {
      if (item.tonnage < 30) {
        this.selectedContextItem = item;
        this.$refs.menu.open(event);
      }
    },

    customPlan(item){
      this.currentHaveType = item;
      this.selectItem(this.selectedContextItem, item);
    },

    isLongText(text) {
      return text.length > 20;
    },
    truncateText(text, maxLength) {
      return this.isLongText(text) ? text.slice(0, maxLength) + "..." : text;
    },
    seledtedOrder(item) {
      this.selected = item;
      this.$modal.show("vehicle-modal");
    },
    changeDriver(val, item) {
      item.driver = val;
    },

    normalizePlate(plate) {
        if (plate === null || plate === '' || plate == undefined) {
            return "";
        }
        return plate.toUpperCase().replace(/\s/g, "");
    },

    //araç manuel atama durum kontrolü yapılacak burada
    async changeVehicle(val, item) {
      const vehicle = this.vehicleList.find((vehicle) => this.normalizePlate(vehicle.plate) == this.normalizePlate(val));
      const isRent = vehicle.haveType ? vehicle.haveType : vehicle.isRent;

      const distancePriority = calculateDistancePriority(
        item,
        vehicle
      );
      const distance = parseFloat(distancePriority).toFixed(2);

      const cost = await calculator(
        this.$store.state.userData.token,
        1,
        1,
        this.vehicle,
        item,
        distance,
        0,
        true,
        true,
        1,
        distance / 50
      );
      
      if (item.vehicle.name != vehicle.vehicle) {
        const oldVehicleIndex = this.vehicleList.findIndex(
          (vehicle) => vehicle.plate == item.vehicle.name
        );
        this.vehicleList[oldVehicleIndex].orderNos = [];
        item.vehicle = {
          id: vehicle.id,
          name: vehicle.vehicle,
          position: {
            lat: vehicle.latitude,
            lng: vehicle.longitude,
          },
        };
        item.isRent = isRent;
        item.isCustom = 1;
        item.vehicleName = vehicle.vehicle;
        item.orderAmount = item.tonnage;
        item.washPointName = cost.washPointName;
        item.recomendationTotalCost = Number(cost.total);
        item.washCost = Number(cost.totalWashPriceCost);
        item.allCost = Number(cost.total) + Number(cost.totalWashPriceCost);
        item.recomendation = {
          vehicleInspectionEndDate: vehicle.vehicleInspectionEndDate,
          inspectionEndDate: vehicle.inspectionEndDate,
          vehicleAdrEndDate: vehicle.vehicleAdrEndDate,
          dorseAdrEndDate: vehicle.dorseAdrEndDate,
          srcEndTime: vehicle.srcEndTime,
          name: vehicle.vehicle,
          position: {
            lat: vehicle.latitude,
            lng: vehicle.longitude,
          },
          customerPriority: calculateCustomerPriority(item, this.customers),
          distancePriority: calculateDistancePriority(
            { startLat: item.outpointLat, startLng: item.outpointLng },
            vehicle
          ).toFixed(2),
          ...isVehicleSuitableForOrderDetail(
            item,
            vehicle,
            this.products,
            false,
            false
          ),
          capacity: (vehicle.capacity / 1000).toFixed(2),
          isWashing: isWashStatus(this.customers, this.products, vehicle, item)
            .status,
        };
      }
    },
    getLastThreeWords(address) {
      if (address) {
        const words = address.split(" ");
        // Eğer kelime sayısı 3'ten azsa, tüm kelimeleri al
        const lastThreeWords =
          words.length <= 3
            ? address
            : words[words.length - 2] + " / " + words[words.length - 3];
        return lastThreeWords;
      } else return;
      // Adresi boşluk karakterlerine göre ayır
    },

    // utils functions
    isProductValid(product) {
      const findItem = this.products.find(
        (item) =>
          similarity(
            item?.name?.toString().toLocaleLowerCase().replace(/\s/g, ""),
            product?.toString().toLocaleLowerCase().replace(/\s/g, "")
          ) > 0.95
      );
      if (!findItem) return false;
      else {
        if (Array.isArray(findItem.dorseTypes))
          if (findItem.dorseTypes.length == 0) return false;
          else return true;
      }
    },
    isContent(val) {
      const {
        customer,
        deliveredName,
        deliveryDate,
        deliveryTime,
        invoicedCompany,
        orderDate,
        orderName,
        orderNo,
        outpointAdress,
        outpointLat,
        outpointLng,
        productType,
        receivedName,
        targetAdress,
        targetPointLat,
        targetPointLng,
        tonnage,
        unitType,
      } = val;

      return !(
        !customer ||
        !deliveredName ||
        !deliveryDate ||
        !deliveryTime ||
        !invoicedCompany ||
        !orderDate ||
        moment(deliveryDate).diff(moment(orderDate), "days") < 0 ||
        !orderName ||
        !orderNo ||
        !outpointAdress ||
        !outpointLat ||
        !outpointLng ||
        !productType ||
        !receivedName ||
        !targetAdress ||
        !targetPointLat ||
        !targetPointLng ||
        !tonnage ||
        !unitType
      );
    },
    isContentNames(val) {
      const {
        customer,
        deliveredName,
        deliveryDate,
        deliveryTime,
        invoicedCompany,
        orderDate,
        orderName,
        orderNo,
        outpointAdress,
        outpointLat,
        outpointLng,
        productType,
        receivedName,
        targetAdress,
        targetPointLat,
        targetPointLng,
        tonnage,
        unitType,
      } = val;

      return [
        !customer ? "Müşteri " : "",
        !deliveredName ? "Teslim Edilen Ad " : "",
        !deliveryDate ? "Teslim Tarihi" : "",
        !deliveryTime ? "Teslimat Saati" : "",
        !invoicedCompany ? "Faturalı Şirket" : "",
        !orderDate ? "Sipariş Tarihi" : "",
        !orderName ? "Sipariş Adı" : "",
        !orderNo ? "Sipariş No" : "",
        !outpointAdress ? "Dolum Noktası Adresi" : "",
        !outpointLat ? "Dolum Noktası Enlem" : "",
        !outpointLng ? "Dolum Noktası Boylam" : "",
        !productType ? "Ürün" : "",
        !receivedName ? "Gönderici Adı" : "",
        !targetAdress ? "Teslim Noktası Adresi" : "",
        !targetPointLat ? "Teslim Noktası Enlem" : "",
        !targetPointLng ? "Teslim Noktası Boylam" : "",
        !tonnage ? "Tonage (Miktar)" : "",
        !unitType ? "Birim" : "",
      ].filter((r) => r != "");
    },
    checkSelectedOrder(val) {
      return this.selectedList.find((item) => {        
        return parseInt(item.orderNo) == parseInt(val);
      });
    },
    showConfirm() {
      this.swalBox(
        "warning",
        "UYARI",
        "BU SİPARİŞ KAYIT EDİLDİKTEN SONRA TOPLU PLANLAMA EKRANINDAN PLANLANABİLİR!",
        false,
        "Tamam"
      );
    },

    async handleSelected(item, blockedVehicles = [], haveType=null) {
      this.inProgress = false;
      if (moment(item.deliveryDate).isBefore(moment())) {
        this.swalBox("warning", "Uyarı", "Lütfen dikkat: Siparişinizin teslim tarihi geçmiştir. ", false, this.$t("general.OkayTitle"));
        return;
      }
      if (!this.isProductValid(item.productType) || !this.isContent(item)) {
        if (!this.isProductValid(item.productType)) {
          if (confirm("ÜRÜN KARTI TANIMLANMAMIŞ VEYA İLGİLİ ÜRÜN KARTINDA İLGİLİ DEĞERLER DOLDURULMAMIŞ!")) {
            this.productName = item.productType;
            this.$modal.show("add-product-modal");
            return;
          } else return;
        } else {
          if (moment(item.deliveryDate).diff(moment(item.orderDate), "days") < 0) {
            this.swalBox("warning", "Uyarı", "Lütfen dikkat: Siparişinizin teslim tarihi, sipariş tarihinden önce belirlenmiş gibi görünüyor. Siparişinizin doğru işlenmesi için, teslim tarihini sipariş tarihinden sonraki bir tarih olarak ayarlayın ve ardından işleminizi yeniden deneyin.", false, this.$t("general.OkayTitle"));
          } else {
            this.swalBox("warning", "Uyarı", "Sipariş Bilgileri Eksik Lütfen Aşağıdaki Alanların Doldurulduğundan Emin Olun : " + "\n" + this.isContentNames(item).join(","), false, this.$t("general.OkayTitle"));
          }
          return;
        }
      }
      const findState = this.checkSelectedOrder(item.orderNo);

      if (findState) {
        this.inProgress = false;
        
        if (confirm("Planlamayı iptal etmek istediğinize emin misiniz?")) {
          const findIndex = this.selectedList.findIndex((sItem) => parseInt(sItem.orderNo) == parseInt(item.orderNo));
          this.selectedList.splice(findIndex, 1);
          this.vehicleList.forEach((item) => {
            if (
              !this.selectedList.find((vitem) => vitem.vehicle.id == item.id)
            ) {
              item.orderNos.pop();
            }
          });
        }
      } else {
        if (item.unitType === 'TON' ? item.tonnage > 30 : item.tonnage > 1) {
          this.selectedList.push({
            ...item,
            vehicleName: "",
            vehicle: {
              id: "",
              name: "",
            },
            isMultiple: true,
            driver: "",
            dorse: "",
            distance: 0,
          });
          
          this.inProgress = false;
        } else {
          const detail = await this.processOrderItem(item, blockedVehicles, haveType);
          if (detail) {
            this.selectedList.push({...detail, haveType});
          }

          this.inProgress = false;
        }
      }
    },
    async selectItem(item, haveType=null) {
      this.currentHaveType = null;
      if ( this.inProgress ) return;

      this.inProgress = true;

      const findState = this.checkSelectedOrder(item.orderNo);
      const defaultTonnage = 26;
      this.tripTonnage = defaultTonnage;
      if (item.unitType === 'SEFER' && item.tonnage === 1 && !findState) {
        const isSmallOrder = await this.confirmSmallOrder();
        this.tripTonnage = isSmallOrder ? 20 : defaultTonnage;
      }
      this.handleSelected(item,[],haveType);
    },
    async confirmSmallOrder() {
      const result = await this.swalBox(
        "warning",
        "UYARI !",
        "Siparişiniz 20 ton veya altında mı?",
        true,
        "Evet",
        "Hayır"
      );
    return result.isConfirmed;
    },

    // recomendations system
    async processOrderItem(item, blockedVehicles = [], haveType = null, noCentipede) {
      const recommendedResult = await this.vehicleRecomended(item, blockedVehicles, haveType, noCentipede);
      const recommended = recommendedResult ? recommendedResult[0] : false;

      if (!recommended) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Lütfen ürün kartını kontrol ediniz. Uygun bir araç bulunamadı, bu sorun ürünün taşınması için seçilen dorse türünün uygun olmamasından kaynaklanıyor olabilir. Ürünün taşınması için gerekli olan dorse türünün doğru şekilde atandığından emin olunuz.",
          false,
          this.$t("general.OkayTitle")
        );
        return;
      }
      let params = {
        ...item,
        vehicleName: recommended.vehicle,
        washCost: recommended.washCost,
        washPointName: recommended.washPointName,
        recomendationTotalCost: recommended.recomendationTotalCost,
        allCost: recommended.allCost,
        vehicle: {
          id: recommended.id,
          name: recommended.vehicle,
          isRent: recommended.haveType,
          position: {
            lat: recommended.latitude,
            lng: recommended.longitude,
          }
        },
        orderAmount: item.tonnage,
        recomendation: {
          ...recommended.recomendation,
          id: recommended.id,
          name: recommended.vehicle,
          position: {
            lat: recommended.latitude,
            lng: recommended.longitude,
          },
          vehicleInspectionEndDate: recommended.vehicleInspectionEndDate,
          inspectionEndDate: recommended.inspectionEndDate,
          vehicleAdrEndDate: recommended.vehicleAdrEndDate,
          dorseAdrEndDate: recommended.dorseAdrEndDate,
          srcEndTime: recommended.srcEndTime,
          lastProductAvailable: recommended.lastProductAvailable,
        },

        isMultiple: item.unitType === 'TON' ? item.tonnage > 30 : item.tonnage > 1,
        driver: recommended.driverName,
        dorse: recommended.dorse,
        distance: recommended.distance ? recommended.distance : 0,
      };

      if (recommended.fleetCustomers.length && !recommended.fleetCustomers.some(
        (customer) => customer?.customerId === item?.customerId
      )) {
        const customers = recommended?.fleetCustomers
          .map((customer) => customer?.customerName)
          .join(", ");

        const acc = await this.swalBox(
          "warning",
          "UYARI !",
          `Seçilen aracın (${recommended.vehicle}) ait olduğu filo başka müşteriye atanmış: ${customers}. Devam etmek için DEVAM ET'e, yeniden araç önerisi yapmak için YENİDEN ÖNER'e basınız?`,
          true,
          "Devam et",
          "Yeniden Öner"
        );

        if (acc.isConfirmed) {
          return params;
        } else {
          const blockedVehicleArr = [...blockedVehicles, recommended];
          return await this.processOrderItem(item, blockedVehicleArr, this.currentHaveType);
        }
      } else {
        return params;
      }
    },

    async vehicleRecomended(item, blockedVehicles = [], haveType = null, noCentipede = false) {
      const order = this.createOrderObject(item, haveType);
      const isTwentyOrLess = parseFloat(item.tonnage) <= 20;
      const newBlockedVehicles = [...blockedVehicles, this.selectedVehicles];

      if (!isTwentyOrLess || noCentipede) {
        const res = await this.getRecommendedVehicle(order, newBlockedVehicles);
        return res;
      }

      const recommendedCentipede = await this.getRecommendedVehicle(order, newBlockedVehicles, true);

      if (!recommendedCentipede) {
        const shouldUseTruck = await this.swalBox(
          "warning", 
          "UYARI !",
          "Siparişe uygun kırkayak bulunamadı, çekici önermek ister misiniz?",
          true,
          "Evet",
          "Hayır"
        );

        if (shouldUseTruck.isConfirmed) {
        const res = await this.getRecommendedVehicle(order, newBlockedVehicles);
          return res;
        }
      }
      const recommendedVehicles = await this.getRecommendedVehicle(order, newBlockedVehicles);
      const costs = await this.compareCosts(recommendedVehicles, recommendedCentipede, order);

      if (costs.centipedeTotal > costs.recommendedTotal) {
        const costDiff = costs.centipedeTotal - costs.recommendedTotal;
        const shouldUseTruck = await this.swalBox(
          "warning",
          "UYARI !",
          `${recommendedVehicles[0].vehicle} plakalı çekicinin maliyeti ${recommendedCentipede[0].vehicle} plakalı kırkayaktan ${costDiff.toFixed(2)} ₺ daha az çekiciyle devam etmek ister misiniz?`,
          true,
          "Çekiciyle devam et",
          "Kırkayakla devam et"
        );

        return shouldUseTruck.isConfirmed ? recommendedVehicles : recommendedCentipede;
      }

      return recommendedCentipede;
    },

    createOrderObject(item, haveType) {
      return {
        ...item,
        haveType,
        startLat: item.outpointLat,
        startLng: item.outpointLng,
        endLat: item.targetPointLat,
        endLng: item.targetPointLng,
        tonnage: parseFloat(item.tonnage),
        productName: item.productType,
        token: this.$store.state.userData.token
      };
    },

    async getRecommendedVehicle(order, blockedVehicles, isCentipede = false) {
      const res = await recommendSingleVehicleForOrder(
        order,
        this.vehicleList,
        this.customers,
        this.products,
        1,
        false,
        false,
        true,
        blockedVehicles,
        isCentipede,
        this.$store.state.userData.token
      );

      if (!res) {
        return undefined;
      }

      if (res.length === 1) {
        return res;
      }

      const acc = await this.swalBox(
        "warning",
        "UYARI !",
        `Bu sipariş için (${res[1].vehicle}) plakalı aracı şubesine yakınlaştırabilirsin. Bu araç önerlsin mi?`,
        true,
        "Evet",
        "Hayır"
      );

      if (acc.isConfirmed) {
        return res[1];
      } else {
        return res[0];
      }
    },

    async compareCosts(recommendedVehicle, centipedeVehicle, order) {
      const [recomendedCost, centipedeCost] = await Promise.all([
        this.calculateCost(recommendedVehicle[0], order),
        this.calculateCost(centipedeVehicle[0], order)
      ]);
      
      return {
        recommendedTotal: parseFloat(recomendedCost.total),
        centipedeTotal: parseFloat(centipedeCost.total)
      };
    },

    async calculateCost(vehicle, order) {
      return calculator(
        this.$store.state.userData.token,
        1,
        1,
        vehicle,
        order,
        Number(vehicle.distance),
        0,
        false,
        true,
        1
      );
    },

    // axios functions
    getAll(load = true, resetProduct = false) {
      this.List = [];
      this.load = load;
    

      axios.get(orders.getAll, {
        params: {
          search: this.search,
          page: this.currentPage - 1,
          isError: this.activeState == 3 ? 0 : 1,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.userData.token,
        },
      })
        .then((res) => {
          this.pageCount = res.data.data.pageCount;
          this.totalCount = res.data.data.totalCount;
          this.errorCount = res.data.data.errorCount;
          this.successCount = res.data.data.successCount;
          this.List = res.data.data.detail.map((item, index) => {
            return {
              ...item,
              isCustom: 2,
              driverTC: "",
            };
          });   
        })
        .catch((err) => {
          console.log(err);
           // this.errorBox(err.response);
        })
        .finally(async () => {
          if (resetProduct) {
            this.products = await getProducts(this.$store.state.userData.token);
          }
          
          this.load = false;
        });
    },
    async applyItem() {
      try {
        const invalidItems = this.selectedList?.filter(
          (item) => !(item.driver && item.driverTC) && (item.unitType === 'TON' ? item.tonnage <= 30 : item.tonnage === 1)
        );
        if (invalidItems?.length) {
          const vehicles = invalidItems
            .map((item) => item.vehicleName)
            .join(", ");
          this.swalBox(
            "warning",
            "Uyarı!",
            `Planlama yapılamaz. Aşağıdaki ${invalidItems?.length > 1 ? "araçlar" : "araç"
            } için sürücü bilgisi veya TC kimlik bilgisi eksik: ${vehicles} Plakalı ${invalidItems.length > 1 ? "araçlara" : "araca"
            } Lütfen sürücü atayınız veya mevcut sürücünün TC kimlik bilgisini giriniz.`
          );
          return;
        }
        this.load = true;

        if (this.selectedList.length > 0) {
          await axios.post(
            orders.applyVehicle,
            {
              orderList: this.selectedList,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          );
          this.isPlanning = false;
          this.selectedAll = [];
          this.selectedList = [];
          this.activeState = 1;
          this.getAll();
        } else {
          this.load = false;
          this.swalBox(
            "warning",
            "UYARI",
            "Lütfen ilk önce herhangi bir sipariş seçin",
            false,
            "Tamam"
          );
        }
      } catch (error) {
        this.load = false;
        this.swalBox(
          "warning",
          "UYARI",
          error?.response?.data?.message ? error.response.data.message : "İşlem gerçekleştirilemedi!",
          false,
          "Tamam"
        );
      }
    },
    toLocaleLowerCase(val) {
      return val.toLocaleLowerCase("tr-TR").replace(/[\u0300-\u036f\s]/g, "");
    },
    searchFilter(order) {
      if (this.search) {
        return this.toLocaleLowerCase(order.orderNo.toString()).includes(
          this.toLocaleLowerCase(this.search)
        ) ||
          this.toLocaleLowerCase(order.customer).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.deliveredName).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.invoicedCompany).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.orderName).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.receivedName).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.tonnage.toString()).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.outpointAdress).includes(
            this.toLocaleLowerCase(this.search)
          ) ||
          this.toLocaleLowerCase(order.productType).includes(
            this.toLocaleLowerCase(this.search)
          )
          ? true
          : false;
      } else {
        return true;
      }
    },
  },
  computed: {
    getOrderList() {
      const list = this.List;
      if (this.activeState == 2) {
        if (this.search) {   
          return this.selectedList.filter((order) => this.searchFilter(order));
        } else return this.selectedList;
      } else {
        return list.filter((order) => !this.checkSelectedOrder(order.orderNo));
      }
    },
    totalCounter() {
      const errorsCount = this.List.filter(
        (order) =>
          !this.isProductValid(order.productType) || !this.isContent(order)
      ).length;
      const planningCount = this.selectedList.length;
      const unPlanningCount = this.List.length - planningCount - errorsCount;

      return {
        unPlanning: unPlanningCount,
        planning: planningCount,
        error: errorsCount,
      };
    },
    getFilterVehicle() {
      return this.vehicleList.map((item) => {
        return {
        ...item,
          name: item.vehicle,
          isRent: item?.haveType,
        };
      });
    },
  },
  async created() {
    const tabIndex = this.$store.state.pages.List.findIndex(
      (i) => i.id === this.$route.name
    );
    if (tabIndex !== -1) {
      this.vehicleList = await getLocationAll(this.$store.state.userData.token);
    }
    this.load = true;
    if (this.vehicleList.length == 0) {
      this.vehicleList = await getLocationAll(this.$store.state.userData.token);
    }
    if (this.customers.length == 0)
      this.customers = await getCustomers(this.$store.state.userData.token);
    if (this.products.length == 0)
      this.products = await getProducts(this.$store.state.userData.token);
    
    this.getAll();
  },
  filters: {
    getDateFormat(val) {
      if (val) return moment(val).format("LLL");
      else return "Belirtilmemiş";
    },
    getDeliveryDateFormat(val) {
      if (val) return moment(val).format("D MMMM YYYY");
      else return "Belirtilmemiş";
    },
  },
  watch: {
    activeState(val) {
      if(val!=2)this.getAll();
    },
    currentPage(val) {
      this.getAll();
    },
    serchControl(val) {
      if (val.length == 0) this.search = val;
    },
    selectedList(val) {
      const newBlockedVehicles = val.map(item => ({plate: item.vehicleName}))

      this.selectedVehicles = newBlockedVehicles
    }
  },
};
</script>

<style>
  @import '~vue-context/dist/css/vue-context.css';
</style>
