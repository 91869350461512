<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900">
    <routeReportModal :detail="selected" />
    <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
      <LyrausTable
        :columns="columns"
        :data="List"
        :isLoading="load"
        :enablePagination="true"
        :totalCount="totalCount"
        :pageCount="pageCount"
        @update-current-page="handleCurrentPage"
        :paginationButtonColor="'red-600'"
        :paginationHoverColor="'red-700'"
        :alternateRowBg="['white', 'gray-100']"
        :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
        :tdClass="'whitespace-nowrap border-gray-200 text-xs py-2'"
        :buttonsColumn="{ label: 'İşlem', width: 40 }"
        :batchOperations="true"
        :searchBar="true"
        @update-search-value="(val) => handleSearchValue(val)"
        :handleSearch="handleSearch"
      >
        <template v-slot:colButtons="{ item }">
          <div class="flex items-center justify-end px-2">
            <button @click="showPreviewMapModal(item)">
              <i class="fas fa-book text-gray-700"></i>
            </button>
          </div>
        </template>
        <template v-slot:rowButtons="{ item, columnKey }">
          <div v-if="columnKey === 'actualKm'">
            <span>
              {{ Math.round(item.actualKm) }}
            </span>
          </div>
          <div v-if="columnKey === 'expectedKm'">
            <span>
             {{ Math.round(item.expectedKm) }}
            </span>
          </div>
          <div v-if="columnKey === 'seferiKm'">
            <span>
              {{ Math.round(item.seferiKm) }}
            </span>
          </div>
        </template>
        <template v-slot:batchOperations>
          <form
            @submit.prevent="getAll"
            class="p-2 flex items-center justify-between bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
          >
            <div class="w-full">
              <asyncBtn
                icon="fas fa-search"
                text="Ara"
                loadTitle="Yükleniyor"
                :loadState="load"
                class="w-full md:w-32"
                size="py-2"
              />
            </div>
            <div class="w-full ml-2">
              <asyncBtn
                icon="fas fa-file-excel"
                text="Excel Çıktısı Al"
                loadTitle="Yükleniyor"
                class="w-full md:w-40 bg-green-700"
                size="py-2 px-2"
                type="button"
                @click="excelExport()"
              />
            </div>
          </form>
        </template>
      </LyrausTable>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import axios from "axios";
import moment from "moment";

import { reports } from "../../../networking/urlmanager";

import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

import routeReportModal from "./components/routeReportModal.vue";

export default {
  name: "routeReport",
  components: {
    diffInput,
    asyncBtn,
    routeReportModal,
  },
  data() {
    return {
      load: false,
      selected: undefined,
      columns: [
        { label: "Sipariş No", key: "orderNo" },
        { label: "Güzergah ID", key: "routeId" },
        { label: "Araç Plakası", key: "vehicle" },
        {
          label: "Yapılan Kilometre",
          key: "actualKm",
          dontDisplay: true,
        },
        {
          label: "Tahmini Kilometre",
          key: "expectedKm",
          dontDisplay: true,
        },
        {
          label: "Seferi Beklenen Kilometre",
          key: "seferiKm",
          dontDisplay: true,
        },
        { label: "Sefer Sayısı", key: "routeCount" },
      ],
      currentPage: 0,
      totalCount: 0,
      pageCount: 1,
      List: [],
      realList: [],
      search: "",
    };
  },
  methods: {
    handleSearchValue(val) {
      this.search = val;
    },
    handleSearch() {
      const searchTerm = this.search.toLowerCase();
      const filteredList = this.realList.filter((item) =>
        item.plate.toLowerCase().includes(searchTerm)
      );
      this.List = filteredList;
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    showPreviewMapModal(item) {
      this.selected = item;
      setTimeout(() => {
        this.$modal.show("route-report-modal");
      }, 50);
    },
    async getAll() {
      this.load = true;
      const params = {
        page: this.currentPage,
      };
      try {
        const res = await axios.get(reports.getOptimumRoutes, {
          params,
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        });
        const { detail, totalCount, pageCount } = res.data.data;

        this.totalCount = totalCount;
        this.pageCount = pageCount;
        this.List = detail;
        this.realList = detail;
      } catch (err) {
        this.errorBox(err.response);
      } finally {
        this.load = false;
      }
    },
    async excelExport() {
      const workbook = XLSX.utils.book_new();
      const headers = {
        orderNo: "Sipariş No",
        routeId: "Güzergah ID",
        vehicle: "Araç Plakası",
        actualKm: "Yapılan Kilometre",
        expectedKm: "Tahmini Kilometre",
        seferiKm: "Seferi Beklenen Kilometre",
        routeCount: "Sefer Sayısı",
      };

      const detail = [
        Object.values(headers), // Başlıklar
        ...this.List.map((item) => {
          return Object.keys(headers).map((key) => {
            if (key === "orderNo") return item.orderNo;
            if (key === "routeId") return item.routeId;
            if (key === "vehicle") return item.vehicle;
            if (key === "actualKm") return Math.round(item.actualKm);
            if (key === "expectedKm") return Math.round(item.expectedKm) / 2;
            if (key === "seferiKm") return Math.round(item.seferiKm);
            if (key === "routeCount") return item.routeCount;
            return item[key] || "";
          });
        }), // Veriler
      ];

      // Verileri worksheet'e dönüştür
      const worksheet = XLSX.utils.json_to_sheet(detail);

      XLSX.utils.sheet_add_aoa(worksheet, [["", "", "", "", "", "", ""]], {
        origin: "A1",
      });

      // Worksheet'i workbook'a ekle
      XLSX.utils.book_append_sheet(workbook, worksheet, "routeReports");

      // Excel dosyasını oluştur
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Dosyayı kaydet
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(data, "route-reports.xlsx");
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
