var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":"action-reports-modal","height":_vm.isMobile ? '100%' : 'auto',"width":_vm.isMobile ? '100%' : '50%',"scrollable":true}},[_c('div',{staticClass:"pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"},[_c('h4',{staticClass:"inline-block w-11/12 text-xl"},[_vm._v("AKSİYON RAPORU")]),_c('button',{staticClass:"p-2 w-1/12",on:{"click":function($event){return _vm.$modal.hide('action-reports-modal')}}},[_c('i',{staticClass:"fas fa-times"})])]),_c('div',{staticClass:"p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"},[_c('p',{staticClass:"border-b pb-2 px-2 mb-5 font-bold"},[_vm._v("GENEL")]),_c('div',{staticClass:"w-full"},[_c('diffInput',{attrs:{"value":_vm.detail?.state,"title":"Durum","type":"text","disabled":"true"}})],1),_c('div',{staticClass:"flex items-center justify-between mt-5"},[_c('div',{staticClass:"w-full"},[_c('diffInput',{attrs:{"value":_vm.detail?.tonnage + ' TON',"title":"Toplam Taşıma Miktarı","type":"text","disabled":"true"}})],1),_c('div',{staticClass:"w-full pl-3"},[_c('diffInput',{attrs:{"value":(_vm.detail?.receivedTonnage ? _vm.detail?.receivedTonnage : '0.00') +
            ' TON',"title":"Toplam Taşınan Miktarı","type":"text","disabled":"true"}})],1)]),_c('p',{staticClass:"border-b pb-2 px-2 my-5 font-bold"},[_vm._v("SAATLİK")]),_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"w-full"},[_c('diffInput',{attrs:{"value":_vm.detail?.actionState[0]?.perDayEstimatedAmount == null
              ? '-'
              : _vm.detail?.actionState[0]?.perDayEstimatedAmount + ' TON',"title":"Hedeflenen Taşıma Miktarı","type":"text","disabled":"true"}})],1),_c('div',{staticClass:"w-full pl-3"},[_c('diffInput',{attrs:{"value":_vm.detail?.actionState[0]?.dailyShipmentAmount + ' TON',"title":"Gerçekleşen Taşıma Miktarı","type":"text","disabled":"true"}})],1)]),_c('div',{staticClass:"w-full mt-5"},[_c('diffInput',{attrs:{"value":_vm.detail?.actionState[0]?.perVehicleDailyShipmentTonnage + ' TON',"title":"Araç Başı Gerçekleşen Taşıma Miktarı","type":"text","disabled":"true"}})],1),([6, 7].includes(_vm.detail?.stateId))?_c('p',{staticClass:"border-b pb-2 px-2 my-5 font-bold"},[_vm._v(" ÖNERİLEN ")]):_c('p',{staticClass:"text-lg text-left font-bold mt-5 text-red-500 border my-5 p-2 py-3 bg-gray-100 rounded-md"},[_vm._v(" "+_vm._s(_vm.detail?.actionState[0]?.explanation)+" ")]),([6, 7].includes(_vm.detail?.stateId))?_c('div',{staticClass:"flex items-center justify-between mt-5"},[_c('div',{staticClass:"w-full"},[_c('diffInput',{attrs:{"value":_vm.detail?.actionState[0]?.vehicleCount + ' ARAÇ',"title":"Mevcut Araç Sayısı","type":"text","disabled":"true"}})],1),_c('div',{staticClass:"w-full pl-3"},[_c('diffInput',{attrs:{"value":(_vm.detail?.stateId === 7
              ? _vm.detail?.actionState[0]?.vehicleCount +
                _vm.detail?.actionState[0]?.recommendedVehicleCount
              : _vm.detail?.actionState[0]?.vehicleCount -
                _vm.detail?.actionState[0]?.recommendedVehicleCount) + ' ARAÇ',"title":"Önerilen Yeni Araç Sayısı","type":"text","disabled":"true"}})],1)]):_vm._e(),(_vm.detail?.stateId === 7)?_c('div',{staticClass:"w-full mt-4 px-2"},[_c('p',{staticClass:"mt-2 text-base font-bold"},[_vm._v("ARAÇ LİSTESİ")]),(_vm.detail?.actionState[0]?.recommendedVehicleCount > 0)?_c('vehicleList',{attrs:{"totalCount":_vm.detail?.actionState[0]?.recommendedVehicleCount,"haveType":3,"order":_vm.getOrderDetail,"recomendedList":_vm.detail?.actionState[0].recommendedVehicles,"reserveCount":0,"height":'225px',"calculateState":true,"hiddenInsert":true},on:{"change":(val) => (_vm.newVehicleList = val)}}):_vm._e()],1):(_vm.detail?.stateId === 6)?_c('div',{staticClass:"w-full mt-4 px-2"},[_c('p',{staticClass:"mt-2 text-base font-bold"},[_vm._v("ARAÇ LİSTESİ")]),_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_c('thead',{staticClass:"bg-white border-black border-dotted border-b-2 border-opacity-20"},[_c('tr',[_c('th',{staticClass:"table-th text-left border-r border-opacity-20",staticStyle:{"min-width":"50px !important"},attrs:{"scope":"col"}},[_vm._v(" # ")]),_c('th',{staticClass:"table-th text-left border-r border-opacity-20 z-50",staticStyle:{"min-width":"100px !important"},attrs:{"scope":"col"}},[_vm._v(" Araç ")]),_c('th',{staticClass:"table-th text-left border-r border-opacity-20",staticStyle:{"min-width":"200px !important"},attrs:{"scope":"col"}},[_vm._v(" Sürücü ")])])]),_c('tbody',{staticClass:"divide-gray-500",staticStyle:{"background-color":"#3a3a3a"}},_vm._l((_vm.detail?.actionState[0]
              ?.recommendedVehicles),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"table-td border-r border-opacity-20"},[_c('span',[_vm._v("# "+_vm._s(index + 1)+" ")])]),_c('td',{staticClass:"table-td border-r border-opacity-20"},[_vm._v(" "+_vm._s(item.plaque)+" ")]),_c('td',{staticClass:"table-td border-r border-opacity-20"},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)])]):_vm._e(),_c('div',{staticClass:"w-full mt-4 text-right"},[_c('asyncBtn',{staticClass:"w-full md:w-56",attrs:{"icon":"fas fa-check","text":_vm.detail?.stateId === 5 ? 'Gerekli Aksiyonu Aldım' : 'Aksiyon Al',"loadTitle":"Aksiyon Alınıyor","loadState":_vm.load},on:{"click":_vm.save}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }