const { default: axios } = require("axios");
const { managementCosting } = require("@/networking/urlmanager");
const { washPoint } = require("../networking/urlmanager");

function haversineDistance(coords1, coords2) {
  const R = 6371e3;
  const lat1Rad = (Math.PI * coords1.lat) / 180;
  const lat2Rad = (Math.PI * coords2.lat) / 180;
  const deltaLat = ((coords2.lat - coords1.lat) * Math.PI) / 180;
  const deltaLon = ((coords2.lng - coords1.lng) * Math.PI) / 180;

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1Rad) *
      Math.cos(lat2Rad) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return (R * c) / 1000;
}

const periot = {
  km_40000: 40000,
  km_80000: 80000,
  km_150000: 150000,
  km_180000: 180000,
  km_280000: 280000,
};

function replaceToMoney(val) {
  return val.includes(",") ? Number(val.replace(",", ".")) : Number(val);
}

let insurancePrice = 0,
  trafficInsurance = 0,
  trailerInspection = 1821.6, // dinamik çekilecek
  trailerInsurance = 31024, // dinamik çekilecek
  mtvPrice = 0,
  mtv2Price = 0,
  inspectionPrice = 0,
  whellPrice = 0,
  vehiclePerwhellCount = 12,
  engineOil = 0,
  engineConsumption = 40,
  fuelFilterPrice = 0,
  fuelFilterConsumption = 1,
  separFilterPrice = 0,
  separFilterConsumption = 1,
  oilFilterPrice = 0,
  oilFilterConsumption = 1,
  airFilterPrice = 0,
  airFilterConsumption = 1,
  airDryerFilterPrice = 0,
  airDryerFilterConsumption = 1,
  brakePadPrice = 0,
  brakePadChangePrice = 0,
  brakePadConsumptionPrice = 5,
  diskPrice = 0,
  diskChangePrice = 350,
  diskPriceConsumptionPrice = 5,
  clutchPrice = 0,
  clutchChangePrice = 2500,
  clutchConsumptionPrice = 1,
  periodicMaintenanceCost = 0,
  kilometerForFuel = 0.34,
  // motorinPrice = 38 / 1.2,
  motorinPrice = 34.9, // dinamik çekilecek - dinamikleştirildi
  // adbluePrice = 21 / 1.2,
  adbluePrice = 11.66,
  adblueUsePercentage = 0.023,
  contactInformationPrice = 574.78, // dinamik eklenecek
  //operationPerWashPrice = 2000, // dinamik gelicek
  otherPersonnelPerVehiclePrice = 6825, // dinamik gelicek
  investmentCost = 0,
  depreciationPrice = 50000, // dinamikleştirilmeli amortisman araç değer kaybı bedeli
  // driverWagePrice = 41000;
  driverWagePrice = 61000; // dinamikleştirildi sürücü maaşı;  - dinamikleştirildi

let calculatorDataCache = {
  costingData: null,
  washingPoints: null,
  lastFetch: null,
  fetchPromise: null,
};

async function fetchAndCacheData(token) {
  // If there's an ongoing fetch, wait for it
  if (calculatorDataCache.fetchPromise) {
    return calculatorDataCache.fetchPromise;
  }

  // If cache is valid (less than 5 minutes old), use it
  if (
    calculatorDataCache.costingData &&
    calculatorDataCache.lastFetch &&
    Date.now() - calculatorDataCache.lastFetch < 5 * 60 * 1000
  ) {
    return {
      costingData: calculatorDataCache.costingData,
      washingPoints: calculatorDataCache.washingPoints,
    };
  }

  calculatorDataCache.fetchPromise = Promise.all([
    axios.get(managementCosting.getAll, {
      headers: {
        Authorization: "Bareer " + token,
      },
    }),
    axios.get(washPoint.getWashingLocations, {
      headers: {
        Authorization: "Bareer " + token,
      },
    }),
  ])
    .then(([costingResponse, washingPointsResponse]) => {
      const result = {
        costingData: costingResponse.data.data,
        washingPoints: washingPointsResponse.data.data,
      };

      calculatorDataCache.costingData = result.costingData;
      calculatorDataCache.washingPoints = result.washingPoints;
      calculatorDataCache.lastFetch = Date.now();
      calculatorDataCache.fetchPromise = null;

      return result;
    })
    .catch((error) => {
      calculatorDataCache.fetchPromise = null;
      throw error;
    });

  return calculatorDataCache.fetchPromise;
}

async function calculator(
  token,
  vehicleCount = 1,
  totalYearKilometer = 1,
  vehicle = null,
  order = null,
  totalMontlyKilometer = 0,
  hgsPrice = 0,
  isAmortisman = true,
  isOneWay = true,
  dayCount = 0,
  hour = 24
) {
  const { costingData, washingPoints } = await fetchAndCacheData(token);
  const detail = costingData;

  insurancePrice = replaceToMoney(detail[0].price);
  trafficInsurance = replaceToMoney(detail[1].price);
  mtvPrice = replaceToMoney(detail[2].price);
  mtv2Price = replaceToMoney(detail[3].price);
  inspectionPrice = replaceToMoney(detail[4].price);

  whellPrice = replaceToMoney(detail[5].price);
  engineOil = replaceToMoney(detail[6].price);
  fuelFilterPrice = replaceToMoney(detail[7].price);
  separFilterPrice = replaceToMoney(detail[8].price);
  oilFilterPrice = replaceToMoney(detail[9].price);
  airFilterPrice = replaceToMoney(detail[10].price);
  airDryerFilterPrice = replaceToMoney(detail[11].price);

  brakePadPrice = replaceToMoney(detail[12].price);
  brakePadChangePrice = replaceToMoney(detail[13].price);

  diskPrice = replaceToMoney(detail[14].price);
  diskChangePrice = replaceToMoney(detail[15].price);

  clutchPrice = replaceToMoney(detail[16].price);
  clutchChangePrice = replaceToMoney(detail[17].price);

  periodicMaintenanceCost = replaceToMoney(detail[18].price);
  driverWagePrice = replaceToMoney(detail[19]?.price);
  motorinPrice = replaceToMoney(detail[20]?.price);

  adbluePrice = replaceToMoney(detail[22]?.price);
  depreciationPrice = replaceToMoney(detail[23]?.price);
  contactInformationPrice = replaceToMoney(detail[24]?.price);
  otherPersonnelPerVehiclePrice = replaceToMoney(detail[26]?.price);

  let totalWashPriceCost = 0;
  let washPointName;

  const isWashingRequired = vehicle?.recomendation?.isWashing == 1;

  if (isWashingRequired) {
    const bestWashPoint =
      washingPoints
        .filter((r) => {
          return r.properties.some(
            (item) => item.productId === order.productId
          );
        })
        .map((r) => {
          let fillingPointDistance = 0;
          const vehicleDistance = haversineDistance(
            {
              lat:
                typeof vehicle.latitude === "number"
                  ? vehicle.latitude
                  : Number(vehicle.latitude),
              lng:
                typeof vehicle.longitude === "number"
                  ? vehicle.longitude
                  : Number(vehicle.longitude),
            },
            {
              lat: Number(r.latitude),
              lng: Number(r.longitude),
            }
          );

          if (order) {
            fillingPointDistance = haversineDistance(
              {
                lat: order.startLat
                  ? Number(order.startLat)
                  : Number(order.outpointLat),
                lng: order.startLng
                  ? Number(order.startLng)
                  : Number(order.outpointLng),
              },
              {
                lat: Number(r.latitude),
                lng: Number(r.longitude),
              }
            );
          }

          const filteredProperties = r.properties.filter(
            (item) => item.productId === order.productId
          );
          const averagePrice =
            filteredProperties.reduce((sum, item) => sum + item.price, 0) /
              filteredProperties.length || 0;

          return {
            ...r,
            properties: filteredProperties,
            cost: averagePrice,
            distance: vehicleDistance + fillingPointDistance,
          };
        })
        .sort((a, b) => a.distance - b.distance)[0] || null;

    if (bestWashPoint) {
      totalWashPriceCost =
        bestWashPoint.distance * kilometerForFuel * motorinPrice +
        bestWashPoint.cost;

      washPointName = bestWashPoint.name;
    }
  } else {
    totalWashPriceCost = 0;
  }

  // toplam yakıt fiyatı =toplam kilometre * yakar oranı * akaryakıt birim fiyatı
  const fuelTotalPrice = totalMontlyKilometer * kilometerForFuel * motorinPrice;

  // toplam adblue fiyatı = toplam kilometre * adblue yakar oranı * adblue birim fiyatı
  const totalAdbluePrice =
    totalMontlyKilometer * adbluePrice * adblueUsePercentage;

  // toplam lastik fiyatı = ((lastik birim fiyatı * bir araç için gereken lastik sayısı)/ 150000km) * toplam kilometre
  const totalWhellPrice =
    ((whellPrice * vehiclePerwhellCount) / periot.km_150000) *
    totalMontlyKilometer;

  // toplam motor yağı maliyeti=((motor yağı birim  litre fiyatı * bir araç için gerekli litre miktarı)/ 40000km ) * toplam kilometre
  const totalEngineOilPrice =
    ((engineOil * engineConsumption) / periot.km_40000) * totalMontlyKilometer;

  // toplam filtre fiyatı=((tüm filtrelerin birim fiyatı * araç için gerekli miktarı)/ 40000km ) * toplam kilometre
  const TotalfilterPrice =
    ((fuelFilterPrice * fuelFilterConsumption) / periot.km_40000) *
      totalMontlyKilometer +
    ((separFilterPrice * separFilterConsumption) / periot.km_40000) *
      totalMontlyKilometer +
    ((oilFilterPrice * oilFilterConsumption) / periot.km_40000) *
      totalMontlyKilometer +
    ((airFilterPrice * airFilterConsumption) / periot.km_40000) *
      totalMontlyKilometer +
    ((airDryerFilterPrice * airDryerFilterConsumption) / periot.km_40000) *
      totalMontlyKilometer;

  // toplam balata bakım maliyeti= ((balata birim fiyatı * bir araç için gerekli olan balata sayısı + balata değişim ücreti) / 80000km) * toplam kilometre
  const totalBrakePadPrice =
    ((brakePadPrice * brakePadConsumptionPrice + brakePadChangePrice) /
      periot.km_80000) *
    totalMontlyKilometer;

  // toplam firen disk maliyeti=((disk birim fiyatı * bir araç için gerekli olan disk sayısı + disk değişim işcilik ücreti))
  const totalDiskPrice =
    ((diskPrice * diskPriceConsumptionPrice + diskChangePrice) /
      periot.km_180000) *
    totalMontlyKilometer;

  // toplam debriyaj maliyeti=((debriyaj birim fiyatı * bir araç için gerekli debriyaj adeti + debriyaj işcilik ücreti)/280000km)*toplam kilometre
  const totalClutchPrice =
    ((clutchPrice * clutchConsumptionPrice + clutchChangePrice) /
      periot.km_280000) *
    totalMontlyKilometer;

  // periyodik bakım maliyeti=(periyodik bakım maliyeti / 40000km)* toplam kilometre
  const totalperiodicMaintenanceCost =
    (periodicMaintenanceCost / periot.km_40000) * totalMontlyKilometer;

  // toplam resmi sabit giderler=(tüm evrak maliyetleri toplamı) / 12  (aylık maliyet hesaplanır)
  const totalTrafficInsurancePriceAndinsurancePrice =
    (trafficInsurance +
      insurancePrice +
      mtvPrice +
      mtv2Price +
      inspectionPrice +
      trailerInspection +
      trailerInsurance) /
    12;

  const otherCost = contactInformationPrice + otherPersonnelPerVehiclePrice;

  let totalCost =
    fuelTotalPrice +
    totalAdbluePrice +
    totalWhellPrice +
    totalEngineOilPrice +
    TotalfilterPrice +
    totalBrakePadPrice +
    totalDiskPrice +
    totalClutchPrice +
    totalperiodicMaintenanceCost +
    hgsPrice;

  if (isOneWay) {
    if (isAmortisman) {
      totalCost += (depreciationPrice / 30) * dayCount;
    }
    totalCost += (totalTrafficInsurancePriceAndinsurancePrice / 30) * dayCount;
    totalCost += (otherCost / 30) * dayCount;
    totalCost += (investmentCost / 30) * dayCount;
    totalCost += (driverWagePrice / 30) * dayCount;
  } else {
    totalCost +=
      (totalTrafficInsurancePriceAndinsurancePrice / 30) * dayCount * 2;
    totalCost += (otherCost / 30) * dayCount * 2;
    totalCost += (investmentCost / 30) * dayCount * 2;
    totalCost += (driverWagePrice / 30) * dayCount * 2;

    if (isAmortisman) {
      totalCost += (depreciationPrice / 30) * dayCount * 2;
    }
  }

  return {
    total: totalCost.toFixed(2),
    fuelTotalPrice,
    totalAdbluePrice,
    totalWhellPrice,
    totalEngineOilPrice,
    TotalfilterPrice,
    totalBrakePadPrice,
    totalDiskPrice,
    totalClutchPrice,
    totalperiodicMaintenanceCost,
    totalTrafficInsurancePriceAndinsurancePrice,
    totalWashPriceCost: totalWashPriceCost.toFixed(2),
    washPointName,
    otherCost,
    depreciationPrice,
    driverWagePrice,
  };
}

module.exports = {
  calculator,
};
