<template>
  <div class="p-2 bg-gray-200 dark:bg-gray-900" @mousedown.stop="userFilter()">
    <addModal @refresh="getAll()" />
    <combinedOrder
      :bulkId="bulkId"
      @vehicle="(r) => showVehicleList(r)"
      @reportModal="(r) => showDetailModal(r)"
    />
    <editModal @refresh="getAll()" :rowId="selected.id" />
    <!-- <noteDetail :note="note" /> -->
    <notesModal
      :orderId="selected.id"
      :orderStatus="selected.state"
      :isBulk="1"
    />
    <vehicleModalVue
      @refresh="getAll"
      :rowId="selected.id"
      :isDisabled="selected.tonnage <= selected.receivedTonnageForJob"
      :orderNo="selected.orderNo"
      :orderItem="selected"
    />
    <reportModal
      :rowId="selected.id"
      :order-no="selected.orderNo"
      :active="1"
      :moduleId="12"
    />
    <reportModalVue :detail="selected" />
    <div class="w-full">
      <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
        <div class="w-full">
          <LyrausTable
            :data="List"
            :columns="columns"
            :innerTable="innerTableColumns"
            :enablePagination="true"
            :isLoading="load"
            :stickyLeft="['orderNo']"
            :stickyRight="['buttonsColumn']"
            :batchOperations="true"
            :searchBar="true"
            :searchProp="search"
            @update-search-value="(val) => handleSearchValue(val)"
            :handleSearch="handleSearch"
            :alternateRowBg="['white', 'gray-100']"
            :rightExtraSlot="true"
            :dblClickableColumns="dblClickableColumns"
            :buttonsColumn="{ label: 'İşlem' }"
            :pageCount="pageCount"
            :pageProp="currentPage"
            @update-current-page="handleCurrentPage"
            :totalCount="totalCount"
            @dbl-row-click="handleRowClick"
            :paginationButtonColor="'red-600'"
            :paginationHoverColor="'red-700'"
            :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
            :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
            :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
          >
            <template v-slot:colButtons="{ item }">
              <div class="flex items-center justify-end space-x-2 mr-1">
                <button
                  :class="
                    item.isCombineOrder !== 1 ? 'cursor-default opacity-20' : ''
                  "
                  @click="
                    item.isCombineOrder === 1 ? combinedOrderModal(item) : null
                  "
                >
                  <img
                    class="w-4 h-4"
                    src="../../../assets/ship/usersDark.svg"
                    alt=""
                  />
                </button>
              </div>
              <div class="flex items-center justify-end space-x-2 mx-1">
                <button @click="noteDetailModal(item)">
                  <i class="far fa-sticky-note"></i>
                </button>
              </div>
              <div class="flex items-center justify-end space-x-2 mx-1">
                <button
                  :class="{
                    'opacity-30':
                      item.state == 'Tamamlandı' ||
                      item.state == 'Başlamadı' ||
                      item.state == 'Kontrol Ediliyor' ||
                      item.state == 'Son Yükte',
                  }"
                  @click="
                    item.state !== 'Tamamlandı' &&
                      item.state !== 'Kontrol Ediliyor' &&
                      item.state !== 'Başlamadı' &&
                      item.state !== 'Son Yükte' &&
                      completePlanning(item)
                  "
                >
                  <i class="fas fa-check text-gray-700"></i>
                </button>
              </div>
              <div class="flex items-center justify-end space-x-2 mx-1">
                <button @click="showDetailModal(item)">
                  <i class="fas fa-search text-gray-700"></i>
                </button>
              </div>
              <div class="flex items-center justify-end space-x-2 mx-1">
                <button @click="deleteItem(item)">
                  <i class="fas fa-trash text-gray-700"></i>
                </button>
              </div>
            </template>
            <template v-slot:batchOperations>
              <asyncBtn
                icon="fas fa-plus "
                :text="$t('operations.planning.newBtnTitle')"
                class="w-36 btn"
                @click="() => $modal.show('add-plan-modal')"
              />
            </template>
            <template v-slot:rowButtons="{ item, columnKey }">
              <button
                v-if="columnKey === 'state' && [5, 6, 7].includes(item.stateId)"
                @click="showActionPanel(item)"
                class="cursor-pointer"
              >
                <img
                  class="w-5 h-5 ml-3 animate-pulse duration-200 select-none"
                  src="../../../../src/assets/error-icons/warning.png"
                  alt=""
                />
              </button>
              <button
                v-if="columnKey === 'vehicleCount'"
                @click="showVehicleList(item)"
              >
                <div
                  class="flex items-center justify-center border rounded-lg w-8 h-6"
                  :class="
                    item.rejectStatus > 0
                      ? 'animate-pulse border-red-400 bg-red-400 text-gray-100'
                      : 'border-gray-400'
                  "
                >
                  {{ item.vehicleCount }}
                </div>
              </button>
            </template>
            <template v-slot:rightExtra>
              <div class="flex justify-between items-center">
                <!-- <button
                  v-if="clickSearch"
                  class="h-11 w-14 bg-white shadow ml-2 rounded-lg justify-center items-center flex"
                  @click="clearSearch()"
                >
                  <div class="font-bold text-xl">
                    <i class="fas fa-arrow-left"></i>
                  </div>
                </button> -->
                <userFilterModal
                  ref="modalActive"
                  :selectedItems="selectedItems"
                  :load="load"
                  @selected-items-changed="handleSelectedItemsChanged"
                />
              </div>
            </template>
            <template v-slot:sortButton="{ columnKey }">
              <div
                v-if="columnKey === searchColumn"
                @click="clearSearch()"
                class="cursor-pointer mr-1"
              >
                <i class="fas fa-times text-red-600"></i>
              </div>
              <button
                v-if="columnKey === 'orderNo'"
                class="pr-1"
                @click="toggleSort(columnKey)"
              >
                <i v-if="sortColumn !== 'orderNo'" class="fas fa-sort"></i>
                <i
                  v-if="sortState === 1 && sortColumn === 'orderNo'"
                  class="fas fa-sort-alpha-up"
                ></i>
                <i
                  v-if="sortState === 2 && sortColumn === 'orderNo'"
                  class="fas fa-sort-alpha-down"
                ></i>
              </button>
              <button
                v-if="columnKey === 'customer'"
                class="pr-1"
                @click="toggleSort(columnKey)"
              >
                <i v-if="sortColumn !== 'customer'" class="fas fa-sort"></i>
                <i
                  v-if="sortState === 1 && sortColumn === 'customer'"
                  class="fas fa-sort-alpha-up"
                ></i>
                <i
                  v-if="sortState === 2 && sortColumn === 'customer'"
                  class="fas fa-sort-alpha-down"
                ></i>
              </button>
              <button
                v-if="columnKey === 'receivedName'"
                class="pr-1"
                @click="toggleSort(columnKey)"
              >
                <i v-if="sortColumn !== 'receivedName'" class="fas fa-sort"></i>
                <i
                  v-if="sortState === 1 && sortColumn === 'receivedName'"
                  class="fas fa-sort-alpha-up"
                ></i>
                <i
                  v-if="sortState === 2 && sortColumn === 'receivedName'"
                  class="fas fa-sort-alpha-down"
                ></i>
              </button>
              <button
                v-if="columnKey === 'deliveredName'"
                class="pr-1"
                @click="toggleSort(columnKey)"
              >
                <i
                  v-if="sortColumn !== 'deliveredName'"
                  class="fas fa-sort"
                ></i>
                <i
                  v-if="sortState === 1 && sortColumn === 'deliveredName'"
                  class="fas fa-sort-alpha-up"
                ></i>
                <i
                  v-if="sortState === 2 && sortColumn === 'deliveredName'"
                  class="fas fa-sort-alpha-down"
                ></i>
              </button>
              <button
                v-if="columnKey === 'productType'"
                class="pr-1"
                @click="toggleSort(columnKey)"
              >
                <i v-if="sortColumn !== 'productType'" class="fas fa-sort"></i>
                <i
                  v-if="sortState === 1 && sortColumn === 'productType'"
                  class="fas fa-sort-alpha-up"
                ></i>
                <i
                  v-if="sortState === 2 && sortColumn === 'productType'"
                  class="fas fa-sort-alpha-down"
                ></i>
              </button>
              <button
                v-if="columnKey === 'estimatedComplateDate'"
                class="pr-1"
                @click="toggleSort(columnKey)"
              >
                <i
                  v-if="sortColumn !== 'estimatedComplateDate'"
                  class="fas fa-sort"
                ></i>
                <i
                  v-if="
                    sortState === 1 && sortColumn === 'estimatedComplateDate'
                  "
                  class="fas fa-sort-alpha-up"
                ></i>
                <i
                  v-if="
                    sortState === 2 && sortColumn === 'estimatedComplateDate'
                  "
                  class="fas fa-sort-alpha-down"
                ></i>
              </button>
            </template>
            <template v-slot:filterButton="{ columnKey }">
              <statusPanel
                v-if="columnKey === 'state'"
                :statusList="bulkStatus"
                :initialStatus="filterType"
                @setStatusFilter="
                  (val) => ((filterType = val), (actionStateId = val))
                "
              />
            </template>
          </LyrausTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Global Components
// import PaginationComponents from "@/components/general/pagination.vue";
// import searchInput from "@/components/general/search-input.vue";
// import tableLoader from "@/components/general/table-loader.vue";
// import tableResizeBtn from "@/components/general/table.resize.btn.vue";
import notesModal from "@/components/modals/notesModal/index.vue";
import noteDetail from "@/components/modals/noteDetail/index.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import userFilterModal from "@/components/filters/userFiltering/index.vue";
import statusPanel from "@/components/filters/filterTableStatus/index.vue";
import reportModalVue from "./components/action-report-modal.vue";
import reportModal from "@/components/modals/report.modal/index.vue";

// Local Components
import addModal from "./components/add/index.vue";
import editModal from "./components/edit/index.vue";
import vehicleModalVue from "./components/vehicle-list/index.vue";
import combinedOrder from "./components/combinedOrder/index.vue";

// networking
import axios from "axios";
import { bulkPlanning } from "@/networking/urlmanager";

//npm
import moment from "moment";
import { LyrausTable } from "lyraus-ui";

export default {
  name: "collectivePlanning-module",
  components: {
    // PaginationComponents,
    // tableLoader,
    // tableResizeBtn,
    // searchInput,
    notesModal,
    addModal,
    editModal,
    vehicleModalVue,
    reportModalVue,
    asyncBtn,
    userFilterModal,
    statusPanel,
    reportModal,
    combinedOrder,
    noteDetail,
  },
  data() {
    return {
      // isFullScreen: false,
      selectedItems: [],
      List: [],
      note: "",
      columns: [
        {
          key: "orderNo",
          label: "#",
        },
        {
          key: "customer",
          label: "Müşteri",
          maxLength: 16,
        },
        {
          key: "receivedName",
          label: "Dolum",
          maxLength: 16,
        },
        {
          key: "deliveredName",
          label: "Teslim",
          maxLength: 16,
        },
        {
          key: "productType",
          label: "Ürün",
        },
        {
          key: "status",
          label: "Toplam / Kalan",
        },
        {
          key: "vehicleCount",
          label: "Araçlar",
          dontDisplay: true,
          center: true,
        },
        {
          key: "estimatedComplateDate",
          label: "Teslim Süresi",
          isDate: true,
        },
        {
          key: "state",
          label: "Durum",
          width: 160,
        },
      ],

      innerTableColumns: [
        { key: "orderName", label: "Kaynak" },
        { key: "fillingAdress", label: "Dolum Adresi" },
        { key: "deliveryAdress", label: "Teslim Adresi" },
        { key: "invoicedCompany", label: "Fatura Adresi" },
        { key: "estimatedComplateDate", label: "Tahmini Teslim Tarihi" },
      ],
      dblClickableColumns: [
        "customer",
        "receivedName",
        "deliveredName",
        "productType",
      ],
      load: false,
      search: "",
      sortState: 0,
      sortColumn: "",
      searchColumn: "",
      filterArr: [],
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      selected: [],
      filterType: 5,
      clickSearch: false,
      bulkStatus: [
        { id: null, name: "Hepsi" },
        { id: 1, name: "Başlamadı" },
        { id: 2, name: "Devam Ediyor" },
        { id: 3, name: "Durduruldu" },
        { id: 4, name: "Teslim Tarihi Geçti" },
        { id: 5, name: "Kriz Var" },
        { id: 6, name: "Araç Sayısı Fazla" },
        { id: 7, name: "Araç Sayısı Az" },
        { id: 8, name: "Son Yükte" },
        { id: 9, name: "Tamamlandı" },
      ],
      needInterval: true, // app.vue da setInterval için lazım silme
      bulkId: "",
      actionStateId: null,
    };
  },
  methods: {
    noteDetailModal(item) {
      this.selected = item;
      this.$modal.show("notes-modal");
    },
    convertTonnage(data) {
      let tonnage = Math.max(0, data.tonnage);
      let remainingTonnage = Math.max(
        0,
        data.tonnage - data.receivedTonnage
      ).toFixed(2);

      if (data.unitType === "SEFER") {
        // Function to convert tonnage to SEFER
        const convertToSefer = (ton) => {
          if (ton < 20) return 0;
          if (ton >= 20 && ton <= 32) return 1;
          return Math.ceil(ton / 26);
        };

        tonnage = convertToSefer(tonnage);
        remainingTonnage = convertToSefer(parseFloat(remainingTonnage));
      }

      return `${tonnage} / ${remainingTonnage} ${data.unitType || "TON"}`;
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    handleSearchValue(val) {
      this.search = val;
    },
    handleSearch() {
      this.clickSearch = false;
      this.searchColumn = "";
      this.filterType = 5;
      this.currentPage = 0;
      this.getAll();
    },
    clearSearch() {
      this.search = "";
      this.handleSearch();
    },
    userFilter() {
      this.$refs.modalActive.show();
    },
    combinedOrderModal(item) {
      this.bulkId = item.id;
      this.$modal.show("combined-order-modal");
    },
    showDetailModal(item) {
      this.selected = item;
      this.$modal.show("report-modal");
    },
    showVehicleList(item) {
      this.selected = item;
      this.$modal.show("vehicle-list-modal");
    },
    handleRowClick(data, key) {
      this.clickSearch = true;
      this.search = "";
      this.filterType = 5;
      this.currentPage = 0;
      this.searchColumn = key;
      this.search = data[key];
      this.getAll(true);
    },
    toggleSort(column) {
      this.currentPage = 0;
      if (this.sortColumn !== column) {
        this.sortState = 0;
      }
      this.sortColumn = column;
      // Toggle the sort state between 0, 1, and 2
      this.sortState = (this.sortState + 1) % 3;

      // Determine the filter based on the sort state
      let filter = [];
      if (this.sortState === 1) {
        filter = [{ sort: 1, column }];
      } else if (this.sortState === 2) {
        filter = [{ sort: 2, column }];
      } else {
        this.sortColumn = "";
      }

      this.filterArr = [JSON.stringify(filter)];
      this.getAll();
    },
    getAll(load = true) {
      if (load) {
        this.List = [];
        this.load = load;
      }

      const params = {
        page: this.currentPage,
        search: this.search,
        userList: this.selectedItems,
      };

      if (this.sortState) {
        params.filter = this.filterArr;
      }

      if (this.searchColumn) {
        params.column = this.searchColumn;
      }

      if (this.actionStateId) {
        params.actionStateId = this.actionStateId;
      }

      axios
        .get(bulkPlanning.getAll, {
          params,
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then(async (res) => {
          this.List = res.data.data.detail.map((item) => {
            return {
              ...item,
              state:
                item?.actionState !== null ? item?.actionState[0].title : "-",
              stateId:
                item?.actionState !== null
                  ? item?.actionState[0].actionStateId
                  : "-",
              status: this.convertTonnage(item),
              action: null,
            };
          });
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;

          this.load = false;
        })
        .catch((err) => {
          console.log("err", err);

          this.errorBox(err.response);
          this.load = false;
        });
    },
    deleteItem(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Planı silmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              bulkPlanning.delete,
              {
                rowId: item.id.toString(),
                orderNo: item.orderNo,
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );

              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    completePlanning(item) {
      this.swalBox(
        "warning",
        this.$t("general.warningTitle"),
        "Sevkiyatı bitirmek istediğinize emin misiniz ?",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText")
      ).then((acc) => {
        if (acc.isConfirmed) {
          axios
            .post(
              bulkPlanning.completePlanning,
              {
                rowId: item.id,
                tonnage: item.tonnage - item.receivedTonnage,
                orderNo: item.orderNo,
              },

              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );

              this.getAll();
            })
            .catch((err) => {
              this.errorBox(err.response);
            });
        }
      });
    },
    showActionPanel(item) {
      if ([5, 6, 7].includes(item.stateId)) {
        this.selected = item;
        this.$modal.show("action-reports-modal");
      }
    },
    handleSelectedItemsChanged(newSelectedItems) {
      this.selectedItems = newSelectedItems;
    },
  },
  created() {
    const tabIndex = this.$store.state.pages.List.findIndex(
      (i) => i.id === this.$route.name
    );
    if (tabIndex === -1) {
      this.getAll();
    }

    this.$store.commit("addTab", {
      id: this.$route.name,
      title: "Toplu Planlama",
      url: this.$route.path,
    });
  },
  filters: {
    getDateFormat(val) {
      return moment(val).format("LLL");
    },
  },
  watch: {
    currentPage() {
      if (this.currentPage > -1) {
        this.getAll();
      }
    },
    selectedItems() {
      this.getAll();
    },
    actionStateId() {
      this.getAll();
    },
  },
};
</script>
