<template>
  <modal
    name="add-vehicle-modal"
    :height="isMobile ? '100%' : '100%'"
    :width="isMobile ? '100%' : '60%'"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">LİSTEYE YENİ ARAÇ EKLE</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('add-vehicle-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div class="h-auto py-4 mx-4">
      <div>
        <diffInput
          type="numberLength"
          v-model="recomendedVehicleCount"
          :required="true"
          title="Araç Sayısı"
          :min="0"
          :valCount="calculateMaxVehicleCount()"
        />
      </div>

      <bulkVehicle
        class=""
        :totalCount="recomendedVehicleCount"
        @removeVehicle="
          () => (recomendedVehicleCount = Number(recomendedVehicleCount) - 1)
        "
        @addVehicle="
          () => (recomendedVehicleCount = Number(recomendedVehicleCount) + 1)
        "
        @change="(r) => (vehicleList = r)"
        :recomendedCount="orderItem.recomendedCount"
        :deliveryDate="orderItem.complateDate"
        :center="calculateCenter()"
        :order="orderItem"
        :hiddenInsert="false"
        :rowId="rowId"
        network="collective"
        :isLoading="load"
        @update-total-count="handleTotalCount"
      />
      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
          @click="bulkSave()"
        />
      </div>
    </div>
  </modal>
</template>

<script>
// import orderInput from "../../../../../components/devItem/orderInput.vue";

//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import bulkVehicle from "@/components/general/vehicleTable/vehicles.vue";

//Axios
import { bulkPlanning } from "@/networking/urlmanager";
import axios from "axios";
//Utilis
import { getLocationAll } from "@/utils/detailData";
export default {
  props: [
    "rowId",
    "orderNo",
    "capacity",
    "customerList",
    "customers",
    "orderItem",
  ],
  components: {
    diffInput,
    asyncBtn,
    bulkVehicle,
  },
  data() {
    return {
      load: false,
      vehicleList: [],
      recomendedVehicleCount: "",
      bulkvehicleList: [],
      vehicleLoad: false,
      vehicle: "",
      dorseName: "",
      driverName: "",
      driverTC: "",
      longitude: "",
      latitude: "",
    };
  },
  methods: {
    calculateCenter() {
      return {
        lat:
          (this.orderItem.fillingLatitude + this.orderItem.deliveryLatitude) /
          2,
        lng:
          (this.orderItem.fillingLongitude + this.orderItem.deliveryLongitude) /
          2,
      };
    },
    calculateMaxVehicleCount() {
      let total =
        Number(this.orderItem.tonnage) - Number(this.orderItem.receivedTonnage);
      return total > 0 ? Math.ceil(total / 26) : 0;
    },
    handleTotalCount(val) {
      this.recomendedVehicleCount = val;
    },
    bulkSave() {
      const invalidVehicles = this.vehicleList?.filter((item) => {
        return !(item.driverName && item.driverTC);
      });

      if (invalidVehicles?.length > 0) {
        const vehicles = invalidVehicles.map((item) => item.vehicle).join(", ");
        this.swalBox(
          "warning",
          "Uyarı!",
          `Planlama yapılamaz !!! Aşağıdaki ${
            invalidVehicles.length > 1 ? "araçlar" : "araç"
          } için sürücü bilgisi veya TC kimlik bilgisi eksik:${vehicles} Plakalı ${
            invalidVehicles.length > 1 ? "araçlara" : "araca"
          } Lütfen sürücü atayınız veya mevcut sürücünün TC kimlik bilgisini giriniz.`
        );
        return;
      }

      this.load = true;
      axios
        .post(
          bulkPlanning.vehicleAdd,
          {
            vehicleList: this.vehicleList.map((item) => {
              return {
                vehicle: item.vehicle,
                plate: item.vehicle,
                dorse: item.dorse,
                driverName: item.driverName,
                isCustom: item.isCustom || 1,
                driverTC: item.driverTC,
                capacity: item.capacity,
                longitude: item.longitude,
                latitude: item.latitude,
                isWashing: item.recomendation?.isWashing || 3,
              };
            }),
            rowId: this.rowId,
            orderNo: this.orderNo,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
          this.$emit("refresh");
          this.$modal.hide("add-vehicle-modal");
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  computed: {
    getFilterVehicle() {
      return this.vehicleList.map((item) => {
        return {
          name: item.vehicle,
          id: item.id,
          driverName: item.driverName,
          dorse: item.dorse,
          capacity: item.capacity,
          longitude: item.longitude,
          latitude: item.latitude,
        };
      });
    },
  },
  async created() {
    this.vehicleLoad = true;
    this.vehicleList = await getLocationAll(this.$store.state.userData.token);
    // console.log("araçlar:01ANL620:",this.vehicleList.filter(z=>z.plate=='01ANL620'));
    this.vehicleLoad = false;
  },
};
</script>

<style></style>
