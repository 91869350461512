<template>
  <div>
    <userEditModal
      v-if="selected"
      :isShip="true"
      :isStore="true"
      :rowId="rowId"
      :orderNo="orderNo"
      :vehicle="selected"
      @refresh="getAll"
      :activeSpot="activeSpot"
    />
    <addModal
      :orderNo="orderNo"
      :rowId="rowId"
      :orderItem="order"
      @refresh="() => ($emit('refresh', true), getAll())"
    />
    <div class="w-full mt-4">
      <div class="flex items-center justify-between">
        <button
          @click="$modal.show('add-vehicle-modal')"
          class="ml-4 text-center"
          :disabled="isDisabled"
          :class="isDisabled ? 'btn-disabled' : 'btn'"
        >
          <i class="fas fa-plus"></i> Yeni Araç
        </button>
        <div class="w-5/12 pr-4">
          <searchInput
            v-model="search"
            @searcing="handleSearch"
            inputClass="w-full  "
          />
        </div>
        <!-- <div class="w-80 mr-4 flex gap-4">
          <asyncBtn
            @click="stopControl()"
            :loadState="setLoad"
            text="Tümünü Durdur"
            icon=""
            :disabled="isDisabled"
            size="py-1 "
            containerClass="
              w-40 h-10
            "
          />
          <asyncBtn
            @click="startControl()"
            :loadState="setLoad"
            text=" Tümünü Başlat"
            icon=""
            :disabled="isDisabled"
            size="py-1 "
            containerClass=" bg-green-700 hover:bg-green-500 w-40 h-10
            "
          />
        </div> -->
      </div>
      <div
        class="shadow table-height-value border-b overflow-y-scroll border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4 mb-4 mx-4"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead
            class="bg-white border-black border-dotted border-b-2 border-opacity-20"
          >
            <tr>
              <th scope="col" class="table-th text-center">#</th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 200px !important"
              >
                Araç
              </th>

              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 100px !important"
              >
                Dorse
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 160px !important"
              >
                Sürücü
              </th>
              <th
                scope="col"
                class="table-th text-center border-r border-opacity-20"
                style="min-width: 80px !important"
                title="Sefer Sayısı"
              >
                Sefer
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 90px !important"
              >
                Mevcut İş
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 150px !important"
              >
                Durum
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 50px !important"
              >
                Başlat / Durdur
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
              ></th>
            </tr>
          </thead>
          <tbody
            class="divide-y-2 divide-gray-500"
            style="background-color: #3a3a3a"
          >
            <tr
              v-for="(item, index) in List"
              :key="item.id"
              :class="
                item?.isReject == 1 && item?.isDelete == 1 ? 'opacity-30' : ''
              "
            >
              <td class="table-td text-center border-r border-opacity-20">
                {{ index + 1 + currentPage * 20 }}
              </td>
              <td class="table-td border-r border-opacity-20">
                <div class="flex justify-between items-center">
                  <p>{{ item.plaque }}</p>
                  <span
                    class="px-2 bg-red-600 rounded-xl text-white text-xs inline-block font-extralight"
                    v-if="item.isCustom == 1 || item.isReject == 1"
                  >
                    {{
                      item?.isReject == 1 && item?.isDelete == 2
                        ? item?.changeRejectId == 1
                          ? "Reddetti"
                          : "İptal Edildi"
                        : item.isCustom
                        ? "Manuel Atama"
                        : "Araç Silindi"
                    }}
                  </span>
                </div>
              </td>
              <td class="table-td border-r border-opacity-20">
                <p>{{ item.dorse }}</p>
              </td>
              <td class="table-td border-r border-opacity-20">
                <p>{{ item.NAME }}</p>
              </td>
              <td
                class="table-td border-r border-opacity-20 font-bold text-center"
              >
                <p>{{ item.transitionCount }}</p>
              </td>
              <td
                class="table-td border-r border-opacity-20 font-bold text-center"
              >
                <p>
                  {{
                    item.isReleasedFromShip == 1
                      ? "Serbest"
                      : item.isStart == 3
                      ? "Yok"
                      : "#" + item.orderNo
                  }}
                </p>
              </td>

              <td class="table-td border-r border-opacity-20 text-left text-xs">
                <!-- <p>{{item.isStart != 1 ?'Görevlendirilmedi ' :item.stateName ?  item.stateName : 'Görevlendirilmedi!' }}</p> -->
                <p v-if="!(item?.isReject == 1 && item?.isDelete == 1)">
                  {{ item.stateName }}
                </p>
                <p v-else>Silindi</p>
              </td>
              <td
                class="table-td border-r border-opacity-20 font-bold text-center"
              >
                <asyncBtn
                  :disabled="isDisabled || isOrderStopped"
                  :loadState="setLoad"
                  @click="stopedControl(item)"
                  :text="item.isStopped == 2 ? 'Durdur' : 'Başlat'"
                  icon=""
                  size="py-1 "
                  :containerClass="
                    item.isStopped === 1
                      ? isDisabled
                        ? 'bg-green-800 w-40'
                        : 'bg-green-700 hover:bg-green-500 w-40'
                      : 'w-40'
                  "
                />
              </td>
              <td class="table-td border-r border-opacity-20">
                <button
                  :class="
                    isDisabled
                      ? 'text-white border rounded px-2 py-1 border-opacity-30 bg-opacity-30 text-opacity-30 mr-2 cursor-default'
                      : 'process-btn mr-2'
                  "
                  @click="userEditModal(item)"
                >
                  <i class="fas fa-pen"></i>
                </button>
                <button
                  :class="
                    isDisabled
                      ? 'text-white border rounded px-2 py-1 border-opacity-30 bg-opacity-30 text-opacity-30'
                      : 'process-btn'
                  "
                  :disabled="
                    isDisabled || (item?.isReject == 1 && item?.isDelete == 1)
                  "
                  @click="deleteItem(item)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            <tableLoader :load="load" :length="List.length" colspan="9" />
          </tbody>
        </table>
      </div>
      <div class="px-4">
        <PaginationComponents
          v-if="totalCount > 0"
          :totalRowCount="totalCount"
          :pageCount="pageCount"
          :currentPage="currentPage + 1"
          @changePage="(r) => (currentPage = r - 1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
//Npm
import moment from "moment";

//Global Components
import tableLoader from "@/components/general/table-loader.vue";
import PaginationComponents from "@/components/general/pagination.vue";
import userEditModal from "@/components/modals/userEditModal/index.vue";
import searchInput from "@/components/general/search-input.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";

// Local Components
import addModal from "../add.vue";

//networking
import axios from "axios";
import { bulkShipPlanning } from "@/networking/urlmanager";
export default {
  props: ["rowId", "isDisabled", "orderNo", "order", "isOrderStopped"],
  components: {
    tableLoader,
    PaginationComponents,
    userEditModal,
    searchInput,
    addModal,
    asyncBtn,
  },
  data() {
    return {
      // activeAxios: 2,
      // active: 1,
      load: false,
      List: [],
      totalCount: 0,
      pageCount: 0,
      selected: undefined,
      currentPage: 0,
      search: "",
      isAllStopped: 1,
      newisStopped: 2,
      setLoad: false,
      note: "",
      selectedOrder: "",
      orders: [],
    };
  },
  methods: {
    handleSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    stopControl() {
      this.isAllStopped = 1;
      this.type = 2;
      this.orderStatus();
    },
    startControl() {
      this.isAllStopped = 2;
      this.type = 2;
      this.orderStatus();
    },
    stopedControl(item) {
      if (item.isStopped == 2) {
        this.type = 1;
        this.newisStopped = 1;
        this.isAllStopped = 0;
        this.orderStatus(item);
      } else {
        this.newisStopped = 2;
        this.type = 1;
        this.isAllStopped = 0;
        this.orderStatus(item);
      }
    },
    orderStatus(item) {
      this.setLoad = true;
      let newItem = [item];
      let noteInput;
      let orderSelect;
      if (
        item.isStopped == 2 &&
        item.workingVehicles <= Number(item.minShipVehicleCount)
      ) {
        const warningText =
          item.workingVehicles === Number(item.minShipVehicleCount)
            ? "Aracın durdurulması halinde depo için önerilen araç sayısının altına düşeceksiniz yine de devam etmek istiyorsanız, Aşağıdaki siparişlerden hangisi için önerilen araç sayısını 1 eksiltmek istediğinizi seçiniz. "
            : "Depo için önerilen araç sayısının altındasınız yine de devam etmek istiyorsanız, Aşağıdaki siparişlerden hangisi için önerilen araç sayısını 1 eksiltmek istediğinizi seçiniz.";
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("general.warningTitle"),
            text: warningText,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.$t("general.confirmText"),
            cancelButtonText: this.$t("general.rejectText"),
            customClass: {
              popup: "w-1/2",
            },
            html: `<div class="flex flex-col items-center">
                <span class="w-full">${warningText}</span>
                <select id="order" class="swal2-select w-2/3 border-2 rounded-lg">
                  <option value="">Seçiniz</option>
                  ${this.orders
                    .map(
                      (option) =>
                        `<option value="${option.id}">${option.orderName}</option>`
                    )
                    .join("")}
                </select>
                <input id="note" class="swal2-input w-2/3" placeholder="Açıklama">
              </div>`,
            didOpen: () => {
              const popup = this.$swal.getPopup();
              noteInput = popup.querySelector("#note");
              orderSelect = popup.querySelector("#order");
              noteInput.onkeyup = (event) =>
                event.key === "Enter" && Swal.clickConfirm();
              orderSelect.onkeyup = (event) =>
                event.key === "Enter" && Swal.clickConfirm();
            },
            preConfirm: () => {
              this.note = noteInput.value;
              this.selectedOrder = orderSelect.value;
            },
          })
          .then((acc) => {
            if (acc.isConfirmed) {
              const params = {
                isStopped: this.newisStopped,
                isAllStopped: this.isAllStopped,
                shipId: this.rowId,
                // orderNo: this.orderNo,
                list: this.type == 1 ? newItem : this.List,
                bulkId: this.selectedOrder ? this.selectedOrder : false,
                note: this.note ? this.note : false,
                isCritical: true,
              };
              this.handleOrderStatus(params);
            } else {
              this.setLoad = false;
            }
          });
      } else {
        const params = {
          isStopped: this.newisStopped,
          isAllStopped: this.isAllStopped,
          shipId: this.rowId,
          // orderNo: this.orderNo,
          list: this.type == 1 ? newItem : this.List,
          isCritical: false,
        };
        this.handleOrderStatus(params);
      }
    },
    userEditModal(item) {
      this.activeSpot = false;
      this.selected = item.plaque;

      setTimeout(() => {
        this.$modal.show("ueserEdit-modal");
      }, 50);
    },
    handleOrderStatus(params) {
      axios
        .post(
          bulkShipPlanning.vehicleSet,
          { params },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
          this.setLoad = false;
          this.getAll();
        })
        .catch((err) => {
          this.errorBox(err.response);
          this.setLoad = false;
        });
    },
    getAll() {
      this.List = [];
      this.load = true;
      axios
        .get(
          bulkShipPlanning.vehiclesAll +
            "?rowId=" +
            this.rowId +
            "&page=" +
            this.currentPage +
            "&search=" +
            this.search,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data.detail;
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      let noteInput;
      let orderSelect;
      if (item.workingVehicles <= Number(item.minShipVehicleCount)) {
        const warningText =
          item.workingVehicles === Number(item.minShipVehicleCount)
            ? "Aracın silinmesi halinde depo için önerilen araç sayısının altına düşeceksiniz yine de devam etmek istiyorsanız, Aşağıdaki siparişlerden hangisi için önerilen araç sayısını 1 eksiltmek istediğinizi seçiniz. "
            : "Depo için önerilen araç sayısının altındasınız yine de devam etmek istiyorsanız, Aşağıdaki siparişlerden hangisi için önerilen araç sayısını 1 eksiltmek istediğinizi seçiniz.";
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("general.warningTitle"),
            text: warningText,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.$t("general.confirmText"),
            cancelButtonText: this.$t("general.rejectText"),
            customClass: {
              popup: "w-1/2",
            },
            html: `<div class="flex flex-col items-center">
                <span class="w-full">${warningText}</span>
                <select id="order" class="swal2-select w-2/3 border-2 rounded-lg">
                  <option value="">Seçiniz</option>
                  ${this.orders
                    .map(
                      (option) =>
                        `<option value="${option.id}">${option.orderName}</option>`
                    )
                    .join("")}
                </select>
                <input id="note" class="swal2-input w-2/3" placeholder="Açıklama">
              </div>`,
            didOpen: () => {
              const popup = this.$swal.getPopup();
              noteInput = popup.querySelector("#note");
              orderSelect = popup.querySelector("#order");
              noteInput.onkeyup = (event) =>
                event.key === "Enter" && Swal.clickConfirm();
              orderSelect.onkeyup = (event) =>
                event.key === "Enter" && Swal.clickConfirm();
            },
            preConfirm: () => {
              this.note = noteInput.value;
              this.selectedOrder = orderSelect.value;
            },
          })
          .then((acc) => {
            if (acc.isConfirmed) {
              const params = {
                rowId: item.id,
                plaque: item.plaque.toString(),
                bulkId: Number(this.selectedOrder)
                  ? Number(this.selectedOrder)
                  : false,
                shipId: item.shipId,
                orderNo: this.orderNo,
                note: this.note ? this.note : false,
                isCritical: true,
                driverTC: item.driverTC,
              };
              this.handleDelete(params);
            }
          });
      } else {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "Aracı planlamadan çıkarmak istediğinize emin misiniz ?",
          true,
          this.$t("general.confirmText"),
          this.$t("general.rejectText")
        ).then((acc) => {
          if (acc.isConfirmed) {
            const params = {
              rowId: item.id,
              plaque: item.plaque.toString(),
              bulkId: 0, // bağlı olduğu geminin idsini döndürt
              shipId: item.shipId,
              orderNo: this.orderNo,
              isCritical: false,
              driverTC: item.driverTC,
            };
            this.handleDelete(params);
          }
        });
      }
    },
    getOrderList() {
      this.orders = [];
      this.load = true;
      axios
        .get(bulkShipPlanning.orderdersgetAll + "?shipId=" + this.rowId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.orders = res.data.data.detail;
          this.$emit("orderList", this.List);
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    handleDelete(params) {
      axios
        .post(
          bulkShipPlanning.vehicleDelete,
          { params },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          );
          this.$emit("refresh", true);
          this.getAll();
        })
        .catch((err) => {
          this.errorBox(err.response);
        });
    },
  },

  created() {
    this.getAll();
    this.getOrderList();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
  filters: {
    dateFormat(val) {
      return moment(val).format("LLLL");
    },
  },
};
</script>

<style></style>
