<template>
  <div class="min-h-80 p-2 bg-gray-200 dark:bg-gray-900">
    <!-- Table Toggle Controls -->
    <div class="mt-1 flex space-x-4 absolute left-1/3 top-32">
      <button
        @click="activeTable = 'secondary'"
        :class="[
          'px-4 py-2 rounded-lg',
          activeTable === 'secondary'
            ? 'bg-red-600 text-white'
            : 'bg-gray-200 text-gray-700 hover:bg-gray-300',
        ]"
      >
        Devam Edenler
      </button>

      <button
        @click="activeTable = 'deviation'"
        :class="[
          'px-4 py-2 rounded-lg',
          activeTable === 'deviation'
            ? 'bg-red-600 text-white'
            : 'bg-gray-200 text-gray-700 hover:bg-gray-300',
        ]"
      >
        Tamamlananlar
      </button>
    </div>

    <!-- Table Components -->
    <RouteDeviationTable v-if="activeTable === 'deviation'" />
    <SecondaryTable v-else />
  </div>
</template>

<script>
import RouteDeviationTable from "./components/RouteDeviationTable.vue";
import SecondaryTable from "./components/SecondaryTable.vue";

export default {
  name: "TableContainer",
  components: {
    RouteDeviationTable,
    SecondaryTable,
  },
  data() {
    return {
      activeTable: "secondary",
    };
  },
};
</script>
