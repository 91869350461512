<template>
  <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
    <div class="w-full">
      <LyrausTable
        :data="combinedData"
        :columns="columns"
        :enablePagination="true"
        :searchBar="true"
        :searchProp="search"
        @update-search-value="handleSearchValue"
        :handleSearch="handleSearch"
        :isLoading="load"
        :pageCount="pageCount"
        :pageProp="currentPage"
        @update-current-page="handleCurrentPage"
        :totalCount="totalCount"
        :alternateRowBg="['white', 'gray-100']"
        :batchOperations="true"
        :rightExtraSlot="true"
        :paginationButtonColor="'red-600'"
        :paginationHoverColor="'red-700'"
        :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
        :tdClass="'whitespace-nowrap border-gray-200 text-xs py-2'"
        :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
        :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
      >
        <template v-slot:colButtons="{ item }">
          <div class="flex items-center justify-end px-2">
            <button @click="handleAction(item)">
              <i class="fas fa-pen text-gray-700"></i>
            </button>
          </div>
        </template>
        <template v-slot:batchOperations>
          <div class="w-full ml-2">
            <asyncBtn
              icon="fas fa-file-excel"
              text="Excel Çıktısı Al"
              loadTitle="Yükleniyor"
              :loadState="load"
              class="w-full md:w-40 bg-green-700"
              size="py-2 px-2"
              type="button"
              @click="excelExport"
            />
          </div>
        </template>
      </LyrausTable>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import asyncBtn from "@/components/general/asyncBtn.vue";
import axios from "axios";
import { reports, personnelManagement } from "@/networking/urlmanager";
import moment from "moment";

export default {
  name: "route-deviation-table",
  components: {
    asyncBtn,
  },
  data() {
    return {
      load: false,
      search: "",
      selected: { id: "" },
      List: [],
      drivers: [],
      combinedData: [],
      excelList: [],
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      columns: [
        {
          key: "vehiclePlate",
          label: "Plaka",
        },
        {
          key: "orderNo",
          label: "Sipariş No",
        },
        {
          key: "deviationStart",
          label: "Sapma Başlangıcı",
        },
        {
          key: "deviationDistance",
          label: "Sapma Kilometresi",
        },
        {
          key: "isDeviation",
          label: "Sapma Durumu",
        },
        // {
        //   key: "driverName",
        //   label: "Sürücü Adı",
        // },
      ],
    };
  },
  methods: {
    async getDrivers() {
      try {
        const result = await axios.get(
          personnelManagement.getAllNotPagination,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          }
        );
        this.drivers = result.data.data;
        return result.data.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    handleSearch() {
      this.currentPage = 0;
      this.combineAndUpdateData();
    },

    handleCurrentPage(val) {
      this.currentPage = val;
    },

    handleSearchValue(val) {
      this.search = val;
    },

    formatList(list) {
      return list.map((item) => ({
        ...item,
        deviationStart: item.deviationStart
          ? moment(item.deviationStart).format("YYYY-MM-DD HH:mm")
          : "-",
        deviationEnd: item.deviationEnd
          ? moment(item.deviationEnd).format("YYYY-MM-DD HH:mm")
          : "-",
        deviationDuration: item.deviationDuration
          ? `${item.deviationDuration} dk`
          : "-",
        deviationDistance: item.deviationDistance
          ? `${item.deviationDistance} km`
          : "-",
        isDeviation: this.getRequestTypeName(item.isDeviation),
      }));
    },

    async getRouteData() {
      const params = {
        page: this.currentPage,
        search: this.search,
      };

      try {
        const res = await axios.get(
          reports.getRouteDeviationReportNotCompleted,
          {
            params,
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          }
        );

        this.totalCount = res.data.data.totalCount;
        this.pageCount = res.data.data.pageCount;
        return this.formatList(res.data.data.detail);
      } catch (err) {
        this.errorBox(err.response);
        return [];
      }
    },

    async combineAndUpdateData() {
      this.load = true;
      try {
        const [driversData, routeData] = await Promise.all([
          this.getDrivers(),
          this.getRouteData(),
        ]);

        this.combinedData = routeData.map((route) => {
          const matchingDriver = driversData.find(
            (driver) =>
              driver?.plaque?.replace(/\s+/g, "") === route.vehiclePlate
          );
          console.log("route", route);

          return {
            ...route,
            driverName: matchingDriver?.name || "N/A",
            driverTC: matchingDriver?.id,
          };
        });
      } catch (error) {
        console.error("Error combining data:", error);
        this.combinedData = [];
      } finally {
        this.load = false;
      }
    },

    async excelExport() {
      this.load = true;
      try {
        const params = {
          search: this.search,
        };

        const res = await axios.get(reports.getRouteDeviationNotCompletedAll, {
          params,
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        });

        const routeData = this.formatList(res.data.data);
        const driversData = await this.getDrivers();

        const combinedExcelData = routeData.map((route) => {
          const matchingDriver = driversData.find(
            (driver) => driver.vehiclePlate === route.vehiclePlate
          );

          return {
            ...route,
            driverName: matchingDriver?.name || "N/A",
            driverPhone: matchingDriver?.phone || "N/A",
          };
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(combinedExcelData, {
          header: this.columns.map((col) => col.key),
        });

        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          "routeDeviationReport"
        );
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const data = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(data, "route-deviation-report.xlsx");
      } catch (error) {
        console.error("Excel export failed:", error);
        this.errorBox(error);
      } finally {
        this.load = false;
      }
    },

    getRequestTypeName(isDeviation) {
      switch (isDeviation) {
        case 0:
          return "Bitti";
        case 1:
          return "Devam Ediyor";
        default:
          return "Bilinmeyen Durum";
      }
    },

    handleAction(item) {
      let noteInput;

      this.selectedActiveDetail = {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      };
      this.hoverList = [];

      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("general.warningTitle"),
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Onayla",
          cancelButtonText: "Kapat",
          customClass: {
            popup: "w-1/2",
          },
          html: `
            <div class="flex flex-col items-center">
            <div class="p-4">
                <div class="bg-white rounded-lg shadow-md overflow-hidden">
                  <div class="border-b border-gray-100 p-4 flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <h2 class="text-lg font-semibold text-gray-900">Rotadan Çıkan Araç Bildirimi</h2>
                </div>
                  <div class="p-6">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div class="space-y-1">
                        <p class="text-sm font-medium text-gray-500">Sürücü Adı</p>
                        <p class="text-base font-semibold text-gray-900">${item.driverName}</p>
                      </div>

                      <div class="space-y-1">
                        <p class="text-sm font-medium text-gray-500">Sürücü TC</p>
                        <p class="text-base font-semibold text-gray-900">${item.driverTC}</p>
                      </div>

                      <div class="space-y-1">
                        <p class="text-sm font-medium text-gray-500">Sipariş Numarası</p>
                        <p class="text-base font-semibold text-gray-900">${item.orderNo}</p>
                      </div>

                      <div class="space-y-1">
                        <p class="text-sm font-medium text-gray-500">Araç Plaka</p>
                        <p class="text-base font-semibold text-gray-900">${item.vehiclePlate}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <input id="note" class="swal2-input w-full" placeholder="Açıklama">
            </div>`,
          didOpen: () => {
            const popup = this.$swal.getPopup();
            noteInput = popup.querySelector("#note");
            noteInput.onkeyup = (event) =>
              event.key === "Enter" && Swal.clickConfirm();
          },
          preConfirm: () => {
            this.note = noteInput.value;
          },
        })
        .then((acc) => {
          if (acc.isConfirmed) {
            const params = {
              deviationId: item.isDeviation,
              action: this.note,
            };
            axios
              .post(tracking.updateDeviation, params, {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              })
              .then(() => {
                this.swalBox(
                  "success",
                  this.$t("general.successTitle"),
                  "",
                  false,
                  this.$t("general.OkayTitle")
                );
                this.getAll();
              })
              .catch((err) => {
                console.log(err);
              });
          }
          this.startInterval();
        });
    },
  },
  created() {
    this.combineAndUpdateData();
  },
  watch: {
    currentPage() {
      this.combineAndUpdateData();
    },
  },
};
</script>
