var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_vm._l((_vm.List),function(item){return _c('div',{key:item.fleetId,staticClass:"w-full select-none"},[_c('div',{staticClass:"flex items-center px-4 py-3 my-1 transition-all duration-200 text-xs hover:bg-gray-50 cursor-pointer",class:[
        _vm.selected === item && _vm.selectedGroup
          ? 'bg-gray-100 shadow-sm'
          : (_vm.selected === item || _vm.selected === item.fleetId) &&
            !_vm.openSub &&
            !_vm.selectedGroup
          ? 'border-l-4 border-red-500 bg-gray-100 shadow-sm'
          : _vm.selected === item && _vm.openSub && 'bg-gray-100 shadow-sm',
      ],on:{"click":function($event){return _vm.getDetail(item)}}},[(item.fleetId !== null)?_c('i',{staticClass:"fas text-gray-500 mr-3 w-4 text-center",class:[item === _vm.selected && _vm.openSub ? 'fa-minus' : 'fa-plus']}):_vm._e(),_c('span',{staticClass:"font-medium text-gray-700"},[_vm._v(_vm._s(item.fleetName))])]),(_vm.openSub && item === _vm.selected && item.fleetId != null)?_c('div',{staticClass:"pl-4 mt-2 space-y-1"},[(_vm.load)?_c('div',{staticClass:"flex items-center text-gray-500 px-4 py-2"},[_c('i',{staticClass:"fas fa-spinner fa-spin mr-2"}),_c('span',[_vm._v("Yükleniyor...")])]):_vm._l((_vm.parentGroups),function(group){return _c('div',{key:group.groupId},[_c('div',{staticClass:"flex text-xs items-center px-4 py-2 transition-all duration-200 hover:bg-gray-50 cursor-pointer",class:[
              _vm.isGroupSelected(group) &&
                'border-l-4 border-red-500 bg-gray-50',
            ],on:{"click":function($event){return _vm.selectGroup(group)}}},[(_vm.hasSubGroups(group))?_c('i',{staticClass:"fas text-gray-500 mr-3 w-4 text-center",class:[
                _vm.expandedGroups[group.groupId] ? 'fa-minus' : 'fa-plus',
              ],on:{"click":function($event){$event.stopPropagation();return _vm.toggleGroup(group)}}}):_vm._e(),_c('span',{staticClass:"text-gray-700"},[_vm._v(_vm._s(group.groupName))])]),(_vm.expandedGroups[group.groupId])?_c('div',{staticClass:"pl-8 mt-1 space-y-1"},_vm._l((_vm.getSubGroups(group)),function(subGroup){return _c('div',{key:subGroup.groupId,staticClass:"px-4 text-xs py-2 transition-all duration-200 hover:bg-gray-50 cursor-pointer",class:[
                _vm.isGroupSelected(subGroup) &&
                  'border-l-4 border-red-500 bg-gray-50',
              ],on:{"click":function($event){return _vm.selectGroup(subGroup)}}},[_c('span',{staticClass:"text-gray-700"},[_vm._v(_vm._s(subGroup.groupName))])])}),0):_vm._e()])})],2):_vm._e()])}),(_vm.List.length <= 0 && !_vm.load)?_c('div',{staticClass:"text-center py-8"},[_c('p',{staticClass:"text-gray-500 mb-3"},[_vm._v("Herhangi bir grup bilgisi bulunamadı")]),_c('button',{staticClass:"px-4 py-2 bg-red-500 text-white hover:bg-red-600 transition-colors duration-200 font-medium",on:{"click":_vm.getAll}},[_vm._v(" Yeniden Dene ")])]):(_vm.List.length <= 0 && _vm.load)?_c('div',{staticClass:"flex items-center justify-center text-gray-500 py-8"},[_c('i',{staticClass:"fas fa-spinner fa-spin mr-2"}),_c('span',[_vm._v("Yükleniyor...")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }