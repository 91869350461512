<template>
  <modal
    name="edit-planing-order"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '25%'"
  >
    <logModule :moduleId="11" :rowId="Detail.id" />
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200 w-full flex items-center justify-center"
    >
      <h4 class="inline-block w-8/12">Siparişi Düzenle</h4>
      <div class="mt-4 w-4/12 ml-auto flex items-center justify-end">
        <div class="w-6/12">
          <logBtn />
        </div>
        <div class="w-2/12">
          <button class="p-2 w-1/12" @click="$modal.hide('edit-planing-order')">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
    >
      <div class="w-full mb-5 border rounded px-2 py-3 relative">
        <label class="absolute -top-3 left-3 text-sm block w-auto bg-white"
          >Çekici Plakası</label
        >
        <vehicleInputVue
          :optList="getFilterVehicle"
          :load="vehicleLoad"
          :sorted="true"
          :order="Detail"
          v-model="vehicle"
          @changeDriverName="(val) => (driverName = val)"
          @changeDorse="(val) => (dorseName = val)"
        />
      </div>

      <div class="w-full pb-5">
        <diffInput :value="dorseName" :disabled="true" title="Dorse" />
      </div>
      <div class="w-full mb-5">
        <personelSelect
          :value="vehicle.name ? vehicle.name : ''"
          @changeTC="(val) => (driverTC = val)"
          @changeName="(val) => (driverName = val)"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full"
        />
      </div>
    </form>
  </modal>
</template>

<script>
//Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import logModule from "@/components/modals/logModule/index.vue";
import logBtn from "@/components/modals/logModule/logBtn.vue";
import personelSelect from "@/components/devItem/personnelInput.vue";

//Local Components
import vehicleInputVue from "../../orders/components/vehicle-select.vue";

//Utils
import { getLocationAll } from "@/utils/detailData";

//Networking
import axios from "axios";
import { orders } from "@/networking/urlmanager";
export default {
  props: ["Detail"],
  components: {
    diffInput,
    asyncBtn,
    logBtn,
    vehicleInputVue,
    personelSelect,
    logModule,
  },
  data() {
    return {
      load: false,
      vehicleList: [],
      vehicleLoad: false,
      vehicle: "",
      dorseName: "",
      driverName: "",
      driverTC: "",
    };
  },
  methods: {
    show() {
      this.$modal.show("edit-planing-order");
    },
    hide() {
      this.$modal.hide("edit-planing-order");
    },
    detail() {
      this.vehicle = this.vehicleList.find(
        (r) => r.vehicle == this.Detail.vehicle
      );
      this.vehicle = {
        name: this.vehicle?.name ? this.vehicle?.name : this.vehicle?.vehicle,
        id: this.vehicle?.id,
        driverName: this.vehicle?.driverName || "",
        dorse: this.vehicle?.dorse,
      };
    },
    save() {
      if (!this.driverTC || !this.driverName) {
        this.swalBox(
          "warning",
          "Uyarı!",
          !this.driverName
            ? "Siparişi kaydetme işlemini gerçekleştirebilmek için lütfen bir sürücü atayın!."
            : "Lütfen mevcut sürücünün TC kimlik bilgisinin doldurulduğuna emin olun!"
        );
        return;
      }

      this.load = true;
      axios
        .post(
          orders.editPlaningOrder,
          {
            rowId: this.Detail.id,
            orderNo: this.Detail?.orderNo,
            driverName: this.driverName,
            vehicle: this.vehicle.name,
            dorse: this.vehicle.dorse,
            driverTC: this.driverTC,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.load = false;
            this.$emit("refresh", true);
            this.hide();
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  computed: {
    getFilterVehicle() {
      return this.vehicleList.map((item) => {
        return {
          ...item,
          name: item.vehicle,
        };
      });
    },
  },
  async created() {
    this.vehicleLoad = true;
    this.vehicleList = await getLocationAll(this.$store.state.userData.token);
    this.vehicleLoad = false;
    this.detail();
  },
  watch: {
    Detail() {
      this.detail();
      this.dorseName = this.Detail.dorse;
    },
  },
};
</script>
