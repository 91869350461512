<template>
  <multiselect
    v-model="selected"
    :options="sortedOptions"
    :hide-selected="true"
    label="name"
    @remove="$emit('change', 0)"
    track-by="id"
    placeholder="Seçilmedi"
    selectLabel="Seç"
    selectedLabel="Seçildi"
    deselectLabel="Seçimi Kaldır"
    openDirection="bottom"
    :disabled="disabled"
    :loading="load || isLoading"
    @search-change="(val) => changeSearch(val)"
  >
  </multiselect>
</template>

<script>
//Npm
import similarity from "similarity";
import Multiselect from "vue-multiselect";
import moment from "moment";

// Utils
import { recommendSingleVehicleForOrder } from "@/utils/recomendation";
import { getCustomers, getProducts } from "@/utils/detailData.js";
import { isSequential } from "../../../../utils/recomendation";

export default {
  components: {
    Multiselect,
  },
  name: "vehicle-input",
  model: {
    props: "value",
    event: "change",
  },
  props: [
    "value",
    "optList",
    "disabled",
    "load",
    "order",
    "sorted",
    "products",
    "customers",
    "isBulk",
    "warningControl",
    "vehicleSelect",
    "selectedVehicles",
  ],
  data() {
    return {
      selected: "",
      isSearch: false,
      allProducts: undefined,
      sortedOptionsData: [],
      isLoading: false,
      previousSelected: null,
    };
  },
  computed: {
    sortedOptions() {
      if (!this.isSearch && this.optList?.length > 0) {
        return this.sortedOptionsData;
      } else {
        return this.optList;
      }
    },
  },
  methods: {
    async updateSortedOptions() {
      if (this.sorted && !this.isSearch && this.optList?.length > 0) {
        this.isLoading = true;
        try {
          const order = {
            startLat: Number(
              this.order.outpointLat
                ? this.order.outpointLat
                : this.order.fillingLatitude
            ),
            startLng: Number(
              this.order.outpointLng
                ? this.order.outpointLng
                : this.order.fillingLongitude
            ),
            endLat: Number(
              this.order.targetPointLat
                ? this.order.targetPointLat
                : this.order.deliveryLatitude
            ),
            endLng: Number(
              this.order.targetPointLng
                ? this.order.targetPointLng
                : this.order.deliveryLongitude
            ),
            deliveryDate: this.order.deliveryDate
              ? this.order.deliveryDate
              : this.order.estimatedComplateDate,
            tonnage: parseFloat(this.order.tonnage) || 26,
            customer: this.order.customer,
            haveType: this.order.haveType,
            productName: this.order.productType,
            productId: this.order.productId,
          };

          const result = await recommendSingleVehicleForOrder(
            order,
            this.optList,
            this.customers,
            this.allProducts,
            30,
            this.isBulk ? this.isBulk : false,
            false,
            false,
            this.selectedVehicles,
            false,
            this.$store.state.userData.token
          );

          this.sortedOptionsData = result || [];
        } catch (error) {
          console.error("Error updating sorted options:", error);
          this.sortedOptionsData = this.optList;
        } finally {
          this.isLoading = false;
        }
      }
    },
    changeSearch(val) {
      if (val) this.isSearch = true;
      else this.isSearch = false;
    },
    handleSelection(val) {
      if (this.optList?.length > 0 && val) {
        const findItem = this.optList.find((item) => item?.id == val?.id);
        if (
          findItem.fleetCustomers?.length &&
          !findItem.fleetCustomers?.some(
            (customer) => customer?.customerId === this.order?.customerId
          )
        ) {
          const customers = findItem?.fleetCustomers
            .map((customer) => customer.customerName)
            .join(", ");

          this.swalBox(
            "warning",
            "UYARI !",
            `Seçilen aracın ait olduğu filo başka müşteriye atanmış:${customers}. Devam etmek için DEVAM ET'e basınız?`,
            true,
            "Devam et"
          ).then((acc) => {
            if (acc.isConfirmed) {
              this.$emit("change", val);
              this.$emit("changeName", val.name);
              this.$emit("changeDriverName", findItem.driverName);
              this.$emit("changeDorse", findItem.dorse);
            } else {
              this.selected = this.previousSelected;
              this.handleSelection(this.previousSelected);
            }
          });
        } else {
          this.$emit("change", val);
          this.$emit("changeName", val.name);
          this.$emit("changeDriverName", findItem.driverName);
          this.$emit("changeDorse", findItem.dorse);
        }
      }
    },
    async handleGetProducts() {
      this.allProducts = await getProducts(this.$store.state.userData.token);
    },
    async verifiedMessage(message) {
      return this.$swal.fire({
        title: "UYARI!",
        text: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      });
    },
    swalBox(
      icon,
      title,
      text,
      showConfirmButton = true,
      confirmButtonText = ""
    ) {
      return this.$swal.fire({
        icon,
        title,
        text,
        showConfirmButton,
        confirmButtonText,
      });
    },
  },
  async created() {
    if (this.products) {
      this.allProducts = this.products;
    } else {
      await this.handleGetProducts();
    }
    this.selected = this.value;
    if (this.sorted) {
      if (!this.customers || this.customers?.length == 0) {
        this.customers = await getCustomers(this.$store.state.userData.token);
      }
      await this.updateSortedOptions();
    }
  },
  watch: {
    value(val) {
      if (!this.selected) {
        this.selected = val;
      }
    },
    selected(val) {
      this.previousSelected = this.optList.find(
        (item) => item?.id == this.value?.id
      );

      if (val?.id !== this.value?.id) {
        let diffInspectionDate = true,
          dorseAdrEndDate = true,
          vehicleAdrEndDate = true,
          vehicleInspectionEndDate = true,
          srcEndTimeBool = true;

        const findProduct = this.allProducts.find((pValue) => {
          return (
            similarity(
              pValue?.name?.toString().toLocaleLowerCase().replace(/\s/g, ""),
              this.order?.productType
                ?.toString()
                .toLocaleLowerCase()
                .replace(/\s/g, "")
            ) >= 0.8
          );
        });
        const productRequiresADR = findProduct.typeId === 2;

        if (this.sorted) {
          const isSequel = isSequential(val, {
            ...this.order,
            startLat: this.order.outpointLat,
            startLng: this.order.outpointLng,
            endLat: this.order.targetPointLat,
            endLng: this.order.targetPointLng,
          });

          if (!isSequel && this.isSearch) {
            this.swalBox(
              "warning",
              "UYARI!",
              "Bu araç, zaten başka bir işe atanmış olduğu ve mevcut işin belirlenen kriterlere uymadığı için bu işe atanamaz!",
              false,
              this.$t("general.OkayTitle")
            );
            this.selected = this.previousSelected;
            this.handleSelection(this.previousSelected);
            return;
          }
        }
        if (val?.capacity / 1000 <= 22 && this.order?.tonnage > 21) {
          this.verifiedMessage(
            "Sipariş Kırkayak için uygun değil yine de devam etmek istiyor musunuz ? "
          ).then((acc) => {
            if (!acc.isConfirmed) {
              this.selected = "";
              this.$emit("change", val);
              this.$emit("changeName", "");
              this.$emit("changeDriverName", "");
              this.$emit("changeDorse", "");
              return;
            }
          });
        }

        // evrak kontrolü
        if (val?.inspectionEndDate)
          diffInspectionDate =
            moment(val.inspectionEndDate).diff(moment(), "days", 1) > 0;
        if (val?.dorseAdrEndDate)
          dorseAdrEndDate =
            moment(val.dorseAdrEndDate).diff(moment(), "days", 1) > 0;
        if (val?.vehicleAdrEndDate)
          vehicleAdrEndDate =
            moment(val.vehicleAdrEndDate).diff(moment(), "days", 1) > 0;
        if (val?.srcEndTime)
          srcEndTimeBool = moment(val.srcEndTime).diff(moment(), "days", 1) > 0;
        if (val?.vehicleInspectionEndDate)
          vehicleInspectionEndDate =
            moment(val.vehicleInspectionEndDate).diff(moment(), "days", 1) > 0;

        let warningMessage = "";
        if (!diffInspectionDate) {
          warningMessage += "Dorse muayene tarihi dolmuş. ";
        }
        if (!dorseAdrEndDate && productRequiresADR) {
          warningMessage += "Dorse ADR belgesi süresi dolmuş. ";
        }
        if (!srcEndTimeBool && productRequiresADR) {
          warningMessage += "SRC-5 belgesi süresi dolmuş. ";
        }
        if (!vehicleAdrEndDate && productRequiresADR) {
          warningMessage += "Araç ADR belgesi süresi dolmuş. ";
        }
        if (!vehicleInspectionEndDate && val.isRent !== 1) {
          warningMessage += "Araç muayene tarihi dolmuş. ";
        }

        const lastProduct = val?.lastProduct;
        const productTypeMismatch =
          this.order.productType !== lastProduct && lastProduct;

        if (!lastProduct || productTypeMismatch) {
          const productInfo = lastProduct ? `(${lastProduct})` : "";
          const infoStatus = lastProduct ? "uygun değil" : "bilinmiyor";

          this.$swal.fire({
            icon: "warning",
            title: "UYARI!",
            text: `${warningMessage} Aracın son yük bilgisi ${productInfo} ${infoStatus}!`,
            showConfirmButton: true,
            confirmButtonText: "Tamam",
          });

          this.handleSelection(val);
        }

        if (warningMessage) {
          this.$swal
            .fire({
              icon: "warning",
              title: "UYARI!",
              text:
                warningMessage +
                " Lütfen belgelerin geçerliliğini kontrol edin.",
              showConfirmButton: true,
              confirmButtonText: "Tamam",
            })
            .then(() => {
              this.selected = this.previousSelected;
              this.handleSelection(this.previousSelected);
            });
          return;
        } else {
          this.handleSelection(val);
          return;
        }
      }
    },
    async optList() {
      await this.updateSortedOptions();
    },
    async order() {
      await this.updateSortedOptions();
    },
    async selectedVehicles() {
      await this.updateSortedOptions();
    },
  },
};
</script>
