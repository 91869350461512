<template>
  <div class="h-full p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm">
    <noteDetail :note="note" title="Operatör Aksiyonu" />
    <div class="w-full">
      <LyrausTable
        :data="List"
        :columns="columns"
        :enablePagination="true"
        :searchBar="true"
        :searchProp="search"
        @update-search-value="(val) => handleSearchValue(val)"
        :handleSearch="handleSearch"
        :isLoading="load"
        :pageCount="pageCount"
        :pageProp="currentPage"
        @update-current-page="handleCurrentPage"
        :buttonsColumn="{ label: 'Aksiyon', width: 40 }"
        :totalCount="totalCount"
        :alternateRowBg="['white', 'gray-100']"
        :batchOperations="true"
        :rightExtraSlot="true"
        :paginationButtonColor="'red-600'"
        :paginationHoverColor="'red-700'"
        :containerClass="'p-2 bg-gray-50 dark:bg-gray-800 rounded shadow-sm'"
        :tdClass="'whitespace-nowrap border-gray-200 text-xs py-1'"
        :innerThClass="'py-2 text-left text-xxs font-medium bg-red-300 tracking-wider border-gray-200'"
        :innerTdClass="'whitespace-nowrap text-xxs bg-red-200 py-2'"
      >
        <template v-slot:colButtons="{ item, index }">
          <div class="flex items-center justify-end px-2">
            <button @click="noteDetailModal(item, index)">
              <i class="fas fa-book text-gray-700"></i>
            </button>
          </div>
        </template>
        <template v-slot:batchOperations>
          <div class="w-full ml-2">
            <asyncBtn
              icon="fas fa-file-excel"
              text="Excel Çıktısı Al"
              loadTitle="Yükleniyor"
              :loadState="load"
              class="w-full md:w-40 bg-green-700"
              size="py-2 px-2"
              type="button"
              @click="excelExport()"
            />
          </div>
        </template>
      </LyrausTable>
    </div>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import asyncBtn from "@/components/general/asyncBtn.vue";
import noteDetail from "@/components/modals/noteDetail/index.vue";
import axios from "axios";
import { reports } from "@/networking/urlmanager";
import moment from "moment";

export default {
  name: "route-deviation-table",
  components: {
    asyncBtn,
    noteDetail,
  },
  data() {
    return {
      load: false,
      search: "",
      selected: { id: "" },
      List: [],
      excelList: [],
      totalCount: 0,
      pageCount: 1,
      currentPage: 0,
      note: "",
      columns: [
        {
          key: "vehiclePlate",
          label: "Plaka",
        },
        {
          key: "orderNo",
          label: "Sipariş No",
        },
        {
          key: "deviationStart",
          label: "Sapma Başlangıcı",
        },
        {
          key: "deviationEnd",
          label: "Sapma Bitişi",
        },
        {
          key: "deviationDistance",
          label: "Sapma Kilometresi",
        },
        {
          key: "deviationDuration",
          label: "Sapma Süresi",
        },
        {
          key: "isDeviation",
          label: "Sapma Durumu",
        },
        {
          key: "operatorName",
          label: "Operatör Adı",
        },
      ],
    };
  },
  methods: {
    noteDetailModal(item) {
      this.note = item.operatorAction;
      this.$modal.show("noteDetail-modal");
    },
    handleSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    getRequestTypeName(isDeviation) {
      switch (isDeviation) {
        case 0:
          return "Bitti";
        case 1:
          return "Devam Ediyor";
        default:
          return "Bilinmeyen Durum";
      }
    },
    handleCurrentPage(val) {
      this.currentPage = val;
    },
    handleSearchValue(val) {
      this.search = val;
    },
    formatList(list) {
      const newList = list.map((item) => ({
        ...item,
        deviationStart: item.deviationStart
          ? moment(item.deviationStart).format("YYYY-MM-DD HH:mm")
          : "-",
        deviationEnd: item.deviationEnd
          ? moment(item.deviationEnd).format("YYYY-MM-DD HH:mm")
          : "-",
        deviationDuration: item.deviationDuration
          ? `${item.deviationDuration} dk`
          : "-",
        deviationDistance: item.deviationDistance
          ? `${item.deviationDistance} km`
          : "-",
        isDeviation: this.getRequestTypeName(item.isDeviation),
      }));
      return newList;
    },
    getAll() {
      this.List = [];
      this.load = true;
      const params = {
        page: this.currentPage,
        search: this.search,
      };
      axios
        .get(reports.getRouteDeviationReport, {
          params,
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = this.formatList(res.data.data.detail);
          this.totalCount = res.data.data.totalCount;
          this.pageCount = res.data.data.pageCount;
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
        });
    },
    async excelExport() {
      try {
        const workbook = XLSX.utils.book_new();
        const headers = {
          vehiclePlate: "Plaka",
          orderNo: "Sipariş No",
          deviationStart: "Sapma Başlangıcı",
          deviationEnd: "Sapma Bitişi",
          deviationDistance: "Sapma Kilometresi",
          deviationDuration: "Sapma Süresi",
          isDeviation: "Sapma Durumu",
          operatorName: "Operatör Adı",
        };

        const excelData = await this.getExcelData();
        const worksheet = XLSX.utils.aoa_to_sheet([
          Object.values(headers),
          ...excelData.map((item) => [
            item.vehiclePlate || "",
            item.orderNo || "",
            item.deviationStart || "",
            item.deviationEnd || "",
            item.deviationDistance || "",
            item.deviationDuration || "",
            item.isDeviation || "",
            item.operatorName || "",
          ]),
        ]);

        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          "routeDeviationReport"
        );
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        const data = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(data, "route-deviation-report.xlsx");
      } catch (error) {
        console.error("Excel export failed:", error);
        if (this.errorBox) {
          this.errorBox(error);
        }
      }
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
