<template>
  <div class="rounded w-full relative">
    <multiselect
      :options="filterList"
      :value="getList()"
      @select="onSelect"
      @remove="onRemove"
      :multiple="true"
      placeholder="Araç seçiniz"
      label="vehicle"
      track-by="vehicle"
      selectLabel="EKLE"
      deselectLabel="KALDIR"
      :preselect-first="true"
      :close-on-select="false"
      :clear-on-select="false"
      :internal-search="false"
      @search-change="asyncFind"
    >
      <template slot="selection" slot-scope="{ values, isOpen }">
        <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
          {{ values.length }} Araç Seçildi
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import Fuse from "fuse.js";

export default {
  name: "vehicles-select",
  components: {
    multiselect,
  },
  props: ["value", "allCars"],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedList: [],
      filterList: [],
      load: false,
      fuse: null,
    };
  },
  methods: {
    onSelect(val) {
      const findIndex = this.selectedList.findIndex(
        (item) => item.vehicle === val.vehicle
      );
      if (findIndex > -1) {
        const item = this.selectedList[findIndex];
        if (item.status === 3) {
          this.selectedList[findIndex].status = 1;
        }
        if (item.status !== 1) {
          this.selectedList[findIndex].status = 2;
        }
      } else {
        const tag = {
          vehicle: val.vehicle,
          status: 2,
        };
        this.selectedList.push(tag);
      }
      this.$emit("change", this.selectedList);
    },

    onRemove(val) {
      const findIndex = this.selectedList.findIndex(
        (r) => r.vehicle === val.vehicle
      );
      const item = this.selectedList[findIndex];
      if (item.status === 1) {
        this.selectedList[findIndex].status = 3;
      } else {
        this.selectedList.splice(findIndex, 1);
      }
      this.$emit("change", this.selectedList);
    },

    getList() {
      return this.selectedList.filter((r) => r.status !== 3);
    },

    asyncFind(val) {
      if (!this.fuse) return;

      if (!val) {
        this.filterList = [...this.allCars];
        return;
      }

      const results = this.fuse.search(val);
      this.filterList = results.map((result) => result.item);
    },

    initializeFuse() {
      this.fuse = new Fuse(this.allCars, {
        keys: ["vehicle"],
        includeScore: false,
        threshold: 0.2,
        distance: 10,
        minMatchCharLength: 2,
      });
      this.filterList = [...this.allCars];
    },
  },
  watch: {
    allCars: {
      handler() {
        this.initializeFuse();
      },
      immediate: true,
    },
    value: {
      handler(newVal) {
        if (newVal && !this.load) {
          this.selectedList = [...newVal];
        }
      },
      immediate: true,
    },
  },
};
</script>
