<template>
  <modal
    name="action-reports-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '50%'"
    :scrollable="true"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12 text-xl">AKSİYON RAPORU</h4>
      <button class="p-2 w-1/12" @click="$modal.hide('action-reports-modal')">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30"
    >
      <p class="border-b pb-2 px-2 mb-5 font-bold">GENEL</p>
      <div class="w-full">
        <diffInput
          :value="detail?.state"
          title="Durum"
          type="text"
          disabled="true"
        />
      </div>

      <div class="flex items-center justify-between mt-5">
        <div class="w-full">
          <diffInput
            :value="detail?.tonnage + ' TON'"
            title="Toplam Taşıma Miktarı"
            type="text"
            disabled="true"
          />
        </div>
        <div class="w-full pl-3">
          <diffInput
            :value="
              (detail?.receivedTonnage ? detail?.receivedTonnage : '0.00') +
              ' TON'
            "
            title="Toplam Taşınan Miktarı"
            type="text"
            disabled="true"
          />
        </div>
      </div>

      <p class="border-b pb-2 px-2 my-5 font-bold">SAATLİK</p>
      <div class="flex items-center justify-between">
        <div class="w-full">
          <diffInput
            :value="
              detail?.actionState[0]?.perDayEstimatedAmount == null
                ? '-'
                : detail?.actionState[0]?.perDayEstimatedAmount + ' TON'
            "
            title="Hedeflenen Taşıma Miktarı"
            type="text"
            disabled="true"
          />
        </div>
        <div class="w-full pl-3">
          <diffInput
            :value="detail?.actionState[0]?.dailyShipmentAmount + ' TON'"
            title="Gerçekleşen Taşıma Miktarı"
            type="text"
            disabled="true"
          />
        </div>
      </div>

      <div class="w-full mt-5">
        <diffInput
          :value="
            detail?.actionState[0]?.perVehicleDailyShipmentTonnage + ' TON'
          "
          title="Araç Başı Gerçekleşen Taşıma Miktarı"
          type="text"
          disabled="true"
        />
      </div>

      <p
        v-if="[6, 7].includes(detail?.stateId)"
        class="border-b pb-2 px-2 my-5 font-bold"
      >
        ÖNERİLEN
      </p>
      <p
        v-else
        class="text-lg text-left font-bold mt-5 text-red-500 border my-5 p-2 py-3 bg-gray-100 rounded-md"
      >
        {{ detail?.actionState[0]?.explanation }}
      </p>

      <div
        v-if="[6, 7].includes(detail?.stateId)"
        class="flex items-center justify-between mt-5"
      >
        <div class="w-full">
          <diffInput
            :value="detail?.actionState[0]?.vehicleCount + ' ARAÇ'"
            title="Mevcut Araç Sayısı"
            type="text"
            disabled="true"
          />
        </div>
        <div class="w-full pl-3">
          <diffInput
            :value="
              (detail?.stateId === 7
                ? detail?.actionState[0]?.vehicleCount +
                  detail?.actionState[0]?.recommendedVehicleCount
                : detail?.actionState[0]?.vehicleCount -
                  detail?.actionState[0]?.recommendedVehicleCount) + ' ARAÇ'
            "
            title="Önerilen Yeni Araç Sayısı"
            type="text"
            disabled="true"
          />
        </div>
      </div>

      <div class="w-full mt-4 px-2" v-if="detail?.stateId === 7">
        <p class="mt-2 text-base font-bold">ARAÇ LİSTESİ</p>
        <vehicleList
          v-if="detail?.actionState[0]?.recommendedVehicleCount > 0"
          :totalCount="detail?.actionState[0]?.recommendedVehicleCount"
          :haveType="3"
          :order="getOrderDetail"
          :recomendedList="detail?.actionState[0].recommendedVehicles"
          :reserveCount="0"
          :height="'225px'"
          :calculateState="true"
          :hiddenInsert="true"
          @change="(val) => (newVehicleList = val)"
        />
      </div>

      <div class="w-full mt-4 px-2" v-else-if="detail?.stateId === 6">
        <p class="mt-2 text-base font-bold">ARAÇ LİSTESİ</p>
        <table class="min-w-full divide-y divide-gray-200">
          <thead
            class="bg-white border-black border-dotted border-b-2 border-opacity-20"
          >
            <tr>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 50px !important"
              >
                #
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20 z-50"
                style="min-width: 100px !important"
              >
                Araç
              </th>
              <th
                scope="col"
                class="table-th text-left border-r border-opacity-20"
                style="min-width: 200px !important"
              >
                Sürücü
              </th>
            </tr>
          </thead>
          <tbody class="divide-gray-500" style="background-color: #3a3a3a">
            <tr
              v-for="(item, index) in detail?.actionState[0]
                ?.recommendedVehicles"
              :key="index"
            >
              <td class="table-td border-r border-opacity-20">
                <span># {{ index + 1 }} </span>
              </td>
              <td class="table-td border-r border-opacity-20">
                {{ item.plaque }}
              </td>
              <td class="table-td border-r border-opacity-20">
                {{ item.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="
            detail?.stateId === 5 ? 'Gerekli Aksiyonu Aldım' : 'Aksiyon Al'
          "
          loadTitle="Aksiyon Alınıyor"
          :loadState="load"
          @click="save"
          class="w-full md:w-56"
        />
      </div>
    </div>
  </modal>
</template>

<script>
// Global Components
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import vehicleList from "@/components/general/vehicleTable/vehicles.vue";

// Networking
import axios from "axios";
import { bulkPlanning } from "@/networking/urlmanager";

export default {
  name: "action-report-modal",
  props: ["detail"],
  components: {
    diffInput,
    asyncBtn,
    vehicleList,
  },
  data() {
    return {
      load: false,
      newVehicleList: [],
    };
  },
  methods: {
    save() {
      if (this.detail?.stateId === 7 && this.newVehicleList?.length == 0) {
        this.swalBox(
          "warning",
          this.$t("general.warningTitle"),
          "aksiyonu gerçekleştirmek için araç listesinin dolu olması gerekiyor!"
        );
        return;
      }

      this.swalBox(
        "warning",
        " Plan için aksiyonu almak istediğinize emin misiniz     ? ",
        "(Lütfen aldığınız aksiyonu aşağıda açıklayınız...) ",
        true,
        this.$t("general.confirmText"),
        this.$t("general.rejectText"),
        true
      ).then((acc) => {
        if (acc.isConfirmed) {
          this.load = true;
          // recommendedVehicles
          axios
            .post(
              bulkPlanning.saveAction,
              {
                rowId: this.detail.id,
                type: this.detail.stateId,
                vehicleList:
                  this.detail?.stateId === 6
                    ? JSON.stringify(
                        this.detail?.actionState[0]?.recommendedVehicles
                      )
                    : JSON.stringify(this.newVehicleList),
                vehicleCount:
                  this.detail?.actionState[0]?.recommendedVehicleCount,
                note: acc.value.toString(),
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )

            .then(() => {
              this.swalBox(
                "success",
                this.$t("general.successTitle"),
                "",
                false,
                this.$t("general.OkayTitle")
              );
              this.$emit("refresh", true);
              this.load = false;
              this.$modal.hide("action-reports-modal");
            })
            .catch((err) => {
              this.errorBox(err.response);
              this.load = false;
            });
        }
      });
    },
  },

  computed: {
    getOrderDetail() {
      return {
        ...this.detail,
        outpointLat: Number(this.detail.fillingLatitude),
        outpointLng: Number(this.detail.fillingLongitude),
        targetPointLat: Number(this.detail.deliveryLatitude),
        targetPointLng: Number(this.detail.deliveryLongitude),
        deliveryDate: this.detail.estimatedComplateDate,
        tonnage: parseFloat(this.detail.tonnage),
        productType: this.detail.productType,
      };
    },
  },
};
</script>
