<template>
  <modal
    name="route-report-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="true"
  >
    <!-- Modal Header -->
    <div
      class="pb-2 pt-4 pr-4 pl-4 border-b mb-4 border-gray-200 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-200 2xl:h-24 flex items-center justify-between"
    >
      <h4 class="text-xl font-semibold">Bölge Düzenleme</h4>
      <button
        type="button"
        class="px-3 text-lg"
        @click="$modal.hide('route-report-modal')"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Loading Indicator -->
    <div v-if="isLoading" class="w-full h-96 flex items-center justify-center">
      <i class="fas fa-spinner fa-3x text-gray-400 fa-spin"></i>
    </div>

    <!-- Modal Content -->
    <div
      v-if="!isLoading && item"
      class="pb-4 px-4 pt-2 items-start justify-between"
    >
      <div class="mb-4">
        <diffInput
          type="text"
          v-model="name"
          title="Bölge Adı"
          :required="true"
          class="text-base"
          maxlength="255"
        />
      </div>
      <!-- Map Section -->
      <div class="w-full">
        <routeReportMap
          :polygon="item.polyline"
          :lat="item.latitude"
          :lng="item.longitude"
          @handlePolygon="handlePolygon"
        />
      </div>
    </div>

    <!-- Modal Footer -->
    <div
      class="border-t border-gray-200 dark:border-gray-600 px-4 py-3 bg-gray-50 dark:bg-gray-800 flex justify-end"
    >
      <button
        @click="editPolygon"
        class="px-4 py-2 rounded-md shadow-sm text-sm font-medium transition-colors duration-200"
        :class="
          !load
            ? 'bg-blue-600 hover:bg-blue-700 text-white'
            : 'bg-gray-300 cursor-not-allowed'
        "
        :disabled="load"
      >
        Kaydet
      </button>
    </div>
  </modal>
</template>

<script>
// Local Components
import axios from "axios";
import routeReportMap from "./zoneMap.vue";
import { routeZone } from "../../../../networking/urlmanager";
import { encode } from "../../../../utils/encoder";
import diffInput from "@/components/general/diffInput.vue";

export default {
  name: "route-report-modal",
  props: ["detail"],
  components: {
    routeReportMap,
    diffInput,
  },
  data() {
    return {
      item: undefined,
      isLoading: false,
      polygon: [],
      name: "",
      load: false,
    };
  },
  methods: {
    handlePolygon(val) {
      const newPolyline = val.map((coord) => [coord.lat, coord.lng]);
      this.polygon = encode({ polyline: newPolyline });
    },
    editPolygon() {
      if (this.polygon === "BF") {
        this.swalBox(
          "warning",
          "UYARI",
          "Bölge çizmeden kaydedemezsiniz.",
          false,
          this.$t("general.OkayTitle")
        );

        return;
      }

      this.load = true;
      axios
        .post(
          routeZone.edit,
          {
            rowId: this.item.id,
            name: this.name,
            polyline: this.polygon,
            address: this.item.address,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.$modal.hide("route-report-modal");
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
        })
        .finally(() => {
          this.load = false;
          this.$emit("refresh", true);
        });
    },
  },
  watch: {
    detail(val) {
      this.item = val;
      this.name = val.name;
      this.polygon = val?.polyline;
    },
  },
};
</script>
