<template>
  <modal
    name="add-plan-modal"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '80%'"
    :scrollable="true"
    @opened="openModal()"
    :clickToClose="false"
  >
    <div
      class="pt-3 pb-2 pr-4 pl-4 border-gray-100 dark:border-gray-500 dark:bg-gray-900 dark:text-gray-200"
    >
      <h4 class="inline-block w-11/12">YENİ TOPLU SEVKİYAT</h4>
      <button class="p-2 w-1/12" @click="close()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div
      v-if="productDeliveryTimeError"
      class="w-full bg-red-600 h-10 flex items-center px-5"
    >
      <span class="text-white text-sm"
        >Bu Sipariş Belirtilen Süre İçinde Tamamlanamaz.</span
      >
    </div>

    <form
      @submit.prevent="save"
      class="p-4 bg-white dark:bg-gray-900 text-black dark:text-gray-200 border-gray-200 border-b border-t border-opacity-30 overflow-y-auto h-full pb-20 xl:h-auto xl:pb-3"
    >
      <div class="w-full mb-2">
        <orderInput
          :isBulk="true"
          @updateFlag="(flag) => updateFlag(flag)"
          @changeObject="(item) => getDetail(item)"
        />
      </div>

      <div class="md:flex mt-4">
        <div class="w-6/12">
          <diffInput
            type="text"
            :required="true"
            title="Operasyon Türü"
            value="Normal"
            :disabled="true"
          />
        </div>
        <div class="w-6/12 md:pl-2">
          <diffInput
            type="text"
            v-model="productType"
            :required="true"
            title="Mal Sınıfı"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="date"
            minlength="1"
            :required="true"
            v-model="orderDate"
            title="Sipariş Tarihi"
            :disabled="true"
          />
        </div>
        <div class="w-full md:w-6/12 mt-4 md:pl-2">
          <diffInput
            type="select"
            :optList="[
              {
                id: 1,
                name: 'Gidiş Dönüş',
              },
              {
                id: 2,
                name: 'Tek Yön',
              },
            ]"
            minlength="1"
            :required="true"
            v-model="TransitionType"
            title="Sefer Türü"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full md:w-6/12 mt-4">
          <diffInput
            type="text"
            minlength="1"
            :required="true"
            v-model="customer"
            :disabled="true"
            title="Müşteri"
          />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            :title="$t('operations.planning.modal.billedCompany')"
            v-model="invoicedCompany"
            :disabled="true"
          />
        </div>
      </div>

      <div class="md:flex">
        <div class="w-full mt-4 md:w-6/12">
          <diffInput
            type="text"
            :disabled="true"
            title="Gönderici"
            v-model="receivedName"
          />
        </div>
        <div class="w-full mt-4 md:w-6/12 md:pl-2">
          <diffInput
            type="text"
            :disabled="true"
            title="Alıcı"
            v-model="deliveredName"
          />
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full md:w-6/12">
          <diffInput
            type="number"
            v-model="fillingDate"
            :required="true"
            :title="
              typeId == 1
                ? 'Dolum Süresi (Dakika)'
                : 'Liman Tahliye Süresi(Dakika)'
            "
            :min="1"
            :max="10000"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            :min="1"
            type="number"
            v-model="deliveryDate"
            :required="true"
            :title="
              typeId == 1
                ? 'Tahliye Süresi (Dakika)'
                : 'Boşaltmada Geçen Süre (Dakika)'
            "
            :max="10000"
          />
        </div>
      </div>

      <div class="md:flex mt-4" v-if="typeId > 1">
        <div class="w-full md:w-6/12">
          <diffInput
            type="time"
            v-model="endDailyTime"
            :required="true"
            title="Gemi Başlangıç Zamanı"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            type="time"
            v-model="startShipEvacationTime"
            title="Gün Sonu Zamanı"
          />
        </div>
      </div>

      <div class="md:flex mt-4" v-if="typeId > 1">
        <div class="w-full md:w-6/12">
          <diffInput
            type="number"
            v-model="reserveCount"
            :required="true"
            title="Rezerve Araç Sayısı"
            :min="0"
            :max="600"
          />
        </div>
        <div class="w-full md:w-6/12 md:pl-2">
          <diffInput
            min="0"
            type="number"
            v-model="horAtDeliveryTonnage"
            title="Geminin Saatlik Boşaltım Miktarı (Ton)"
            max="10000"
          />
        </div>
      </div>

      <div class="w-full mt-4 px-1" v-if="productType">
        <label>Sipariş Birleştirme</label>

        <combinePanel
          :isLoading="isCombineLoading"
          @changeList="(val) => (this.subItems = val)"
          :fillingAndDelivery="{
            fillingPoint,
            deliveryPoint,
          }"
        />
      </div>

      <div class="w-full mt-4">
        <routeViewiver
          v-if="
            fillingPoint.lat > 0 &&
            fillingPoint.lng > 0 &&
            deliveryPoint.lat > 0 &&
            deliveryPoint.lng > 0
          "
          ref="routerViev"
          :customer="receivedName"
          :outpoint="deliveredName"
          :center="{
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          :startPosition="{
            adress: fillingPoint.adress,
            lat: Number(fillingPoint.lat),
            lng: Number(fillingPoint.lng),
          }"
          @changeStartPosition="(val) => (fillingPoint = val)"
          @toggleRouteLoad="(val) => (isCombineLoading = val)"
          @changeEndPosition="(val) => (deliveryPoint = val)"
          @changeOutpointId="(r) => (receivedName = r)"
          @changeCustomerId="(r) => (deliveredName = r)"
          @changePolyline="(r) => changePolyline(r)"
          :endPosition="{
            adress: deliveryPoint.adress,
            lat: Number(deliveryPoint.lat),
            lng: Number(deliveryPoint.lng),
          }"
          :height="'300px'"
          @change="(r) => (costDistribution = r)"
          :waypointsList="waypoints"
        />
      </div>

      <div class="md:flex mt-4">
        <div class="w-full flex items-center justify-center">
          <div class="w-full">
            <diffInput
              type="number"
              v-model="hakedisKilometer"
              :required="true"
              :disabled="true"
              title="Kilometre"
            />
          </div>
          <div class="w-full md:pl-2">
          <diffInput
            type="select"
            :optList="[
              {
                id: 3,
                name: 'Hepsi',
              },
              {
                id: 1,
                name: 'Kiralık',
              },
              {
                id: 2,
                name: 'Özmal',
              },
            ]"
            minlength="1"
            :required="true"
            v-model="haveType"
            title="Araç Türü"
          />
        </div>
          <div class="ml-2 w-full text-center relative">
            <diffInput
              type="number"
              v-if="typeId == 1"
              v-model="recomendedVehicleCount"
              :required="true"
              :min="0"
              title="Araç Sayısı"
              :step="1"
              :max="1000"
            />

            <label
              v-if="recomendedError"
              class="px-2 py-1 mt-1 shadow-sm rounded-l-lg font-bold bg-red-700 text-white tex-center absolute right-0 -top-3.5 rounded-tr-lg"
              style="font-size: 8px"
            >
              {{ recomendedError }}
            </label>
          </div>
        </div>
      </div>

      <div class="md:flex mt-4">
        <div class="w-full flex items-center justify-center">
          <diffInput
            type="number"
            v-model="mainTonnage"
            :required="true"
            :title="`Toplam ${unitType === 'SEFER' ? 'Sefer' : 'Tonaj'}`"
            :disabled="true"
          />
          <diffInput
            type="datetime-local"
            v-model="mainDeliveryDate"
            class="ml-2"
            title="Tamamlanma süresi"
            :disabled="true"
          />
        </div>
      </div>

      <vehiclesTable
        v-if="detail != null"
        :haveType="haveType"
        :recomendedList="recomendedList"
        :totalCount="recomendedVehicleCount"
        :recomendedCount="recomendedCount"
        :tonnage="tonnage"
        :center="center"
        height="350px"
        ref="vehicleTable"
        @change="(r) => (vehicleList = r)"
        :order="detail"
        @removeVehicle="
          () => (recomendedVehicleCount = Number(recomendedVehicleCount) - 1)
        "
        @addVehicle="
          () => (recomendedVehicleCount = Number(recomendedVehicleCount) + 1)
        "
        :fillingDate="fillingDate"
        :deliveryDate="deliveryDate"
        :reserveCount="reserveCount"
        :shipFillingTime="shipFillingTime"
        :costDistribution="costDistribution"
        @update-total-count="handleTotalCount"
      />

      <div class="w-full mt-4">
        <diffInput
          type="textarea"
          v-model="explanation"
          :title="$t('general.note')"
          maxlength="500"
        />
      </div>

      <div class="w-full mt-4 text-right">
        <asyncBtn
          icon="fas fa-check"
          :text="$t('general.saveButtonTitle')"
          :loadTitle="$t('general.saveButtonLoadTitle')"
          :loadState="load"
          class="w-full md:w-56"
        />
      </div>
    </form>
  </modal>
</template>
<script>
// Npm
import polyLine from "google-polyline";
import moment from "moment";

// Global Components
import routeViewiver from "@/components/devItem/route-generator/index.vue";
import diffInput from "@/components/general/diffInput.vue";
import asyncBtn from "@/components/general/asyncBtn.vue";
import orderInput from "@/components/devItem/orderInput.vue";
import vehiclesTable from "@/components/general/vehicleTable/vehicles.vue";
import combinePanel from "@/components/general/vehicleTable/combine.vue";

// Networking
import axios from "axios";
import { bulkPlanning } from "@/networking/urlmanager";

// Utils
import calculatorModel from "@/utils/vehecleCountRecomendation.js";

import _ from "lodash";

export default {
  name: "add-plan-modal",
  components: {
    diffInput,
    asyncBtn,
    routeViewiver,
    vehiclesTable,
    orderInput,
    combinePanel,
  },
  data() {
    return {
      isCombineLoading: false,
      orderNo: "",
      orderCost: "",
      orderName: "",
      orderDate: "",
      complateDate: "",
      customer: "",
      endDailyTime: "",
      startShipEvacationTime: "",
      horAtDeliveryTonnage: 0,
      invoicedCompany: "",
      receivedName: "",
      deliveredName: "",
      productType: "",
      tonnage: 0,
      detail: null,
      typeId: 1,
      explanation: "",
      hakedisKilometer: "0",
      recomendedVehicleCount: "",
      recomendedCount: 0,
      recomendedError: "",
      reserveCount: 0,
      shipFillingTime: 0,
      fillingDate: 0,
      deliveryDate: 0,
      fillingAdress: "",
      deliveryAdress: "",
      totalTime: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      costDistribution: null,
      fillingPoint: {
        adress: "",
        lat: 0,
        lng: 0,
      },
      deliveryPoint: {
        adress: "",
        lat: "",
        lng: "",
      },
      mainDeliveryPoint: {
        adress: "",
        lat: "",
        lng: "",
      },
      mainDeliveryDate: "",
      mainTonnage: "",
      unitType: "",
      polyLine: "",
      load: false,
      changeLocationState: false,
      productDeliveryTimeError: false, // gereksiz state
      TransitionType: 1,
      haveType: 3,
      flag: true,
      recomendedList: [],
      subItems: [],
      waypoints: [],
      explosiveState: true, // Gereksiz state,
      vehicleList: [],
      debouncedCalculateTotalTime: _.debounce(this.calculateTotalTime, 300),
    };
  },
  methods: {
    handleTotalCount(val) {
      this.recomendedVehicleCount = val;
    },
    openModal() {
      this.recomendedError = "";
    },
    close() {
      this.$modal.hide("add-plan-modal");
      this.resetForm();
    },
    updateFlag(flag) {
      this.flag = flag;
    },
    getDetail(item) {
      var differentDate = parseInt(
        moment
          .duration(moment(new Date(item.deliveryDate)).diff(moment()))
          .asMinutes()
          .toFixed(2)
      );

      if (differentDate < 100) {
        this.swalBox(
          "warning",
          "UYARI!",
          "Bu siparişin teslim süresi geçtiğinden dolayı planlanamaz!",
          false,
          "Tamam"
        );
        return;
      }
      this.detail = item;
      this.orderNo = item.orderNo;
      this.orderCost = item.orderCost;
      this.orderName = item.name;
      this.orderDate = moment(item.orderDate).format("YYYY-MM-DD");
      this.customer = item.customer;
      this.invoicedCompany = item.invoicedCompany;
      this.receivedName = item.receivedName;
      this.deliveredName = item.deliveredName;
      this.productType = item.productType;
      this.tonnage = item?.amount;
      this.unitType = item.unitType;
      this.fillingAdress = item.outpointAdress;
      this.deliveryAdress = item.targetAdress;
      this.fillingPoint = {
        lat: item.outpointLat,
        lng: item.outpointLng,
        adress: item.outpointAdress,
      };
      this.deliveryPoint = {
        lat: item.targetPointLat,
        lng: item.targetPointLng,
        adress: item.targetAdress,
      };

      this.vehicle = item.vehicle;
      this.dorse = item.dorse;
      this.personnel = item.driverName;

      this.totalTime = moment(
        `${item.deliveryDate.split("T")[0]} ${
          item?.deliveryTime ? item.deliveryTime : ""
        }`
      ).format("YYYY-MM-DD HH:mm");

      // deep clone
      this.mainDeliveryPoint = this.deliveryPoint;
      this.mainDeliveryDate = this.totalTime;
      this.mainTonnage =
        this.unitType === "SEFER"
          ? String(Number(this.tonnage) / 26)
          : this.tonnage;

      this.changeLocation();
    },
    changePolyline(val) {
      this.polyLine = polyLine.encode(val);
    },
    save() {
      const invalidVehicles = this.vehicleList?.filter((item) => {
        return !(item.driverName && item.driverTC);
      });

      if (invalidVehicles?.length > 0) {
        const vehicles = invalidVehicles.map((item) => item.vehicle).join(", ");
        this.swalBox(
          "warning",
          "Uyarı!",
          `Planlama yapılamaz !!! Aşağıdaki ${
            invalidVehicles.length > 1 ? "araçlar" : "araç"
          } için sürücü bilgisi veya TC kimlik bilgisi eksik:${vehicles} Plakalı ${
            invalidVehicles.length > 1 ? "araçlara" : "araca"
          } Lütfen sürücü atayınız veya mevcut sürücünün TC kimlik bilgisini giriniz.`
        );
        return;
      }

      if (
        !this.subItems?.length &&
        (!Number(this.recomendedVehicleCount) || !this.vehicleList.length)
      ) {
        this.swalBox(
          "warning",
          "Uyarı!",
          "Planı oluşturabilmek için önerilen araçları talep etmeniz gerekmektedir!"
        );
        return;
      }

      const list = this.vehicleList.map((item) => {
        return {
          vehicle: item.vehicle,
          plate: item.vehicle,
          dorse: item.dorse,
          driverName: item.driverName,
          isCustom: item.isCustom || 0,
          driverTC: item.driverTC,
          capacity: item.capacity,
          longitude: item.longitude,
          latitude: item.latitude,
          isWashing: item.recomendation?.isWashing || 3,
        };
      });
      this.load = true;
      axios
        .post(
          bulkPlanning.add,
          {
            orderNo: this.orderNo,
            orderCost: this.orderCost,
            TransitionType: this.TransitionType,
            polyline: this.polyLine,
            orderName: this.orderName,
            orderDate: this.orderDate,
            complateDate: this.complateDate,
            customer: this.customer,
            invoicedCompany: this.invoicedCompany,

            tonnage:
              this.unitType === "SEFER"
                ? String(this.mainTonnage * 26)
                : String(this.mainTonnage),
            Kilometer: this.hakedisKilometer.toString(),
            recomendedVehicleCount: this.recomendedVehicleCount,
            recomendedCount: this.recomendedCount,
            productType: this.productType,
            unitType: this.unitType,
            
            fillingAdress: this.fillingAdress,
            deliveryAdress: this.deliveryAdress,

            receivedName: this.receivedName,
            fillingLatitude: this.fillingPoint.lat,
            fillingLongitude: this.fillingPoint.lng,
            estimatedFillingTime: this.fillingDate,

            deliveredName: this.deliveredName,
            targetpoint: this.deliveryPoint.adress,
            deliveryLatitude: this.deliveryPoint.lat,
            deliveryLongitude: this.deliveryPoint.lng,
            estimatedDeliveryTime: this.deliveryDate,
            estimatedComplateDate: this.mainDeliveryDate,
            note: this.explanation,
            vehicleList: list ? JSON.stringify(list) : [],
            subList: JSON.stringify(this.subItems),
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.swalBox(
            "success",
            this.$t("general.successTitle"),
            "",
            false,
            this.$t("general.OkayTitle")
          ).then(() => {
            this.resetForm();
            this.load = false;
            this.$emit("refresh", true);
            this.$modal.hide("add-plan-modal");
          });
        })
        .catch((err) => {
          this.errorBox(err.response);
          console.log(err);
          this.load = false;
        });
    },
    resetForm() {
      // this.product = "";
      //this.outpointStateId = "";
      // this.targetpointLat = "";
      // this.targetpointLng = "";
      // this.recomendedError = "";
      this.flag = false;
      this.polyLine = "";
      this.orderNo = "";
      this.orderCost = "";
      this.orderName = "";
      this.orderDate = "";
      this.complateDate = "";
      this.customer = "";
      this.invoicedCompany = "";
      this.receivedName = "";
      this.deliveredName = "";
      this.tonnage = "";
      this.productType = "";
      this.hakedisKilometer = "";
      this.recomendedVehicleCount = "";
      this.recomendedCount = "";
      this.fillingDate = "";
      this.targetpoint = "";
      this.deliveryDate = "";
      this.vehicleList = [];
      this.explanation = "";
      this.fillingPoint = {
        adress: "",
        lat: 0,
        lng: 0,
      };
      this.deliveryPoint = {
        adress: "",
        lat: "",
        lng: "",
      };
      this.subItems = [];
      this.changeLocationState = false;
    },
    changeLocation() {
      this.changeLocationState = false;
      if (
        (this.fillingPoint.lat != 0 && this.fillingPoint.lng != 0) ||
        (this.deliveryPoint.lat != 0 && this.deliveryPoint.lng != 0)
      ) {
        setTimeout(() => {
          this.changeLocationState = true;
          this.$refs.routerViev.getRoute();
        }, 200);
      }
    },
    // changeProductType(state) {
    //   // Kullanılmıyor
    //   this.changeLocationState = false;
    //   this.explosiveState = state;
    //   if (this.fillingPoint.lat > 0 && this.fillingPoint.lng > 0) {
    //     setTimeout(() => {
    //       this.changeLocationState = true;
    //       this.$refs.routerViev.getRoute();
    //     }, 200);
    //   }
    // },
    // getTimeDetail(mins) {
    //   // Kullanılmıyor
    //   var h = (mins / 60) | 0,
    //     m = mins % 60 | 0;
    //   return h + " Saat" + " " + m + " Dakika";
    // },
    async calculateTotalTime() {
      const val = this.costDistribution;
      let totalTonnage = this.tonnage;

      let loadPerVehicle = 26; //her bir aracın bir seferde taşıyabileceği yük miktar

      if (this.TransitionType == 2) loadPerVehicle = 26;
      let fillingDate = Number(this.fillingDate);
      let deliveryDate = Number(this.deliveryDate);

      if (this.subItems?.length > 0) {
        this.subItems.forEach((item) => {
          fillingDate += Number(item.estimatedFillingTime);
          deliveryDate += Number(item.estimatedDeliveryTime);
        });
      }

      const maxDailyDrivingTime = 540;
      if (val == null) return;

      let { requiredVehicles, errorMessage } =
        await new calculatorModel().calculateRecomendedCount(
          this.detail.orderDate,
          this.TransitionType,
          val.totalMinutes,
          fillingDate,
          deliveryDate,
          this.totalTime,
          totalTonnage,
          loadPerVehicle,
          maxDailyDrivingTime,
          this.typeId,
          this.endDailyTime,
          this.startShipEvacationTime,
          this.horAtDeliveryTonnage,
          this.fillingPoint,
          this.$store.state.userData.token
        );
      this.recomendedError = errorMessage;

      if (!Array.isArray(requiredVehicles)) {
        this.subItems?.forEach((subItem) => {
          requiredVehicles = Math.abs(
            requiredVehicles - Number(subItem.recomendedVehicleCount)
          );
        });
        this.recomendedCount = requiredVehicles;
        this.recomendedVehicleCount = requiredVehicles;
      } else {
        this.subItems?.forEach((subItem) => {
          requiredVehicles = Math.abs(
            requiredVehicles - Number(subItem.recomendedVehicleCount)
          );
        });
        this.recomendedCount = requiredVehicles;
        this.recomendedList = requiredVehicles;
      }
    },
    addWaypoints(val) {
      if (val?.length > 0) {
        this.waypoints.push({
          no: this.orderNo,
          position: {
            adress: this.deliveryPoint.adress,
            lat: Number(this.deliveryPoint.lat),
            lng: Number(this.deliveryPoint.lng),
          },
          name:
            this.deliveryPoint.name?.split("-")[0] + "-Sipariş Bitiş Noktası",
        });
        val.forEach((item) => {
          const findItem = this.waypoints.find(
            (val) => val.name == item.orderName + "-Sipariş Başlangıç Noktası"
          );
          if (!findItem) {
            this.waypoints.push({
              no: item?.orderNo,
              name: item?.orderName + "-Sipariş Başlangıç Noktası",
              position: {
                lat: Number(item?.fillingLatitude),
                lng: Number(item?.fillingLongitude),
                adress: item?.fillingAdress,
              },
            });
            this.deliveryPoint = {
              name: item?.orderName + "Sipariş Bitiş Noktası",
              lat: Number(item?.deliveryLatitude),
              lng: Number(item?.deliveryLongitude),
              adress: item?.deliveryAdress,
            };

            this.tonnage = Number(this.tonnage) + Number(item.tonnage);
            // Tüm siparişler eklendikten sonra
            this.tonnage = this.tonnage / (val.length + 1);

            this.totalTime = moment
              .min(moment(this.totalTime), moment(item.estimatedComplateDate))
              .format("YYYY-MM-DD HH:mm");
          }
        });
      } else {
        this.deliveryPoint = {
          ...this.mainDeliveryPoint,
          name: "Teslim Noktası",
        };
      }

      setTimeout(() => {
        this.changeLocation();
      }, 100);
    },
  },
  computed: {
    center() {
      return {
        lat: (this.fillingPoint.lat + this.deliveryPoint.lat) / 2,
        lng: (this.fillingPoint.lng + this.deliveryPoint.lng) / 2,
      };
    },
  },
  watch: {
    costDistribution: {
      handler(val) {
        if (val) {
          this.hakedisKilometer = val.totalKilometer.toFixed(2);
          this.debouncedCalculateTotalTime();
        }
      },
      deep: true,
    },
    deliveryDate() {
      this.debouncedCalculateTotalTime();
    },
    fillingDate() {
      this.debouncedCalculateTotalTime();
    },
    totalTime() {
      this.debouncedCalculateTotalTime();
    },
    tonnage() {
      this.debouncedCalculateTotalTime();
    },
    TransitionType() {
      this.debouncedCalculateTotalTime();
    },
    startShipEvacationTime() {
      this.debouncedCalculateTotalTime();
    },
    endDailyTime() {
      this.debouncedCalculateTotalTime();
    },
    horAtDeliveryTonnage() {
      this.debouncedCalculateTotalTime();
    },
    subItems: {
      handler(val) {
        this.waypoints = [];
        this.addWaypoints(val);
        this.debouncedCalculateTotalTime();
      },
      deep: true,
    },
  },
};
</script>
