<template>
  <div class="w-full">
    <!-- Fleet Items -->
    <div class="w-full select-none" v-for="item in List" :key="item.fleetId">
      <div
        @click="getDetail(item)"
        class="flex items-center px-4 py-3 my-1  transition-all duration-200 text-xs hover:bg-gray-50 cursor-pointer"
        :class="[
          selected === item && selectedGroup
            ? 'bg-gray-100 shadow-sm'
            : (selected === item || selected === item.fleetId) &&
              !openSub &&
              !selectedGroup
            ? 'border-l-4 border-red-500 bg-gray-100 shadow-sm'
            : selected === item && openSub && 'bg-gray-100 shadow-sm',
        ]"
      >
        <i
          v-if="item.fleetId !== null"
          class="fas text-gray-500 mr-3 w-4 text-center"
          :class="[item === selected && openSub ? 'fa-minus' : 'fa-plus']"
        ></i>
        <span class="font-medium text-gray-700">{{ item.fleetName }}</span>
      </div>

      <!-- Groups -->
      <div
        class="pl-4 mt-2 space-y-1"
        v-if="openSub && item === selected && item.fleetId != null"
      >
        <!-- Loading State -->
        <div class="flex items-center text-gray-500 px-4 py-2" v-if="load">
          <i class="fas fa-spinner fa-spin mr-2"></i>
          <span>Yükleniyor...</span>
        </div>

        <!-- Groups List -->
        <template v-else>
          <div v-for="group in parentGroups" :key="group.groupId">
            <div
              @click="selectGroup(group)"
              class="flex text-xs items-center px-4 py-2  transition-all duration-200 hover:bg-gray-50 cursor-pointer"
              :class="[
                isGroupSelected(group) &&
                  'border-l-4 border-red-500 bg-gray-50',
              ]"
            >
              <i
                v-if="hasSubGroups(group)"
                @click.stop="toggleGroup(group)"
                class="fas text-gray-500 mr-3 w-4 text-center"
                :class="[
                  expandedGroups[group.groupId] ? 'fa-minus' : 'fa-plus',
                ]"
              ></i>
              <span class="text-gray-700">{{ group.groupName }}</span>
            </div>

            <!-- Subgroups -->
            <div
              v-if="expandedGroups[group.groupId]"
              class="pl-8 mt-1 space-y-1"
            >
              <div
                v-for="subGroup in getSubGroups(group)"
                :key="subGroup.groupId"
                @click="selectGroup(subGroup)"
                class="px-4 text-xs py-2  transition-all duration-200 hover:bg-gray-50 cursor-pointer"
                :class="[
                  isGroupSelected(subGroup) &&
                    'border-l-4 border-red-500 bg-gray-50',
                ]"
              >
                <span class="text-gray-700">{{ subGroup.groupName }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Empty State -->
    <div class="text-center py-8" v-if="List.length <= 0 && !load">
      <p class="text-gray-500 mb-3">Herhangi bir grup bilgisi bulunamadı</p>
      <button
        class="px-4 py-2 bg-red-500 text-white  hover:bg-red-600 transition-colors duration-200 font-medium"
        @click="getAll"
      >
        Yeniden Dene
      </button>
    </div>

    <!-- Loading State -->
    <div
      class="flex items-center justify-center text-gray-500 py-8"
      v-else-if="List.length <= 0 && load"
    >
      <i class="fas fa-spinner fa-spin mr-2"></i>
      <span>Yükleniyor...</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { tracking } from "@/networking/urlmanager";

export default {
  name: "group-panel",

  data() {
    return {
      List: [],
      subList: [],
      load: false,
      openSub: false,
      selectedGroup: null,
      selected: null,
      expandedGroups: {},
    };
  },

  computed: {
    parentGroups() {
      return this.subList.filter((group) => !group.parentGroupId);
    },
  },

  methods: {
    async getAll() {
      this.load = true;
      try {
        const response = await axios.get(tracking.getFleetAll, {
          headers: {
            Authorization: "Bearer " + this.$store.state.userData.token,
          },
        });

        this.List = [
          {
            fleetId: null,
            fleetName: "All",
          },
        ];
        this.List.push(...response.data.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.load = false;
      }
    },

    async getDetail(item) {
      this.subList = [];
      this.selected = item;
      this.$emit("changeFleet", item?.fleetId);

      if (item.fleetId === null) {
        this.openSub = false;
        this.selectedGroup = null;
        return;
      }

      this.load = true;
      this.openSub = !this.openSub;
      this.selectedGroup = null;

      if (this.openSub) {
        try {
          const response = await axios.get(
            tracking.getGroupAll + "?fleetId=" + item.fleetId,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.userData.token,
              },
            }
          );
          this.subList = response.data.data;
        } catch (err) {
          console.error(err);
        }
      }
      this.load = false;
    },

    selectGroup(group) {
      this.selectedGroup = group;

      // If clicking a parent group, get all sub-group IDs including the parent
      if (this.hasSubGroups(group)) {
        const subGroupIds = this.getSubGroups(group).map((g) => g.groupId);
        // Include parent group ID as well
        subGroupIds.push(group.groupId);
        this.$emit("changeGroup", subGroupIds);
      } else {
        // For leaf nodes, just emit the single group ID
        this.$emit("changeGroup", group.groupId);
      }
    },

    toggleGroup(group) {
      this.$set(
        this.expandedGroups,
        group.groupId,
        !this.expandedGroups[group.groupId]
      );
    },

    hasSubGroups(group) {
      return this.subList.some((g) => g.parentGroupId === group.groupId);
    },

    getSubGroups(group) {
      return this.subList.filter((g) => g.parentGroupId === group.groupId);
    },

    isGroupSelected(group) {
      return this.selectedGroup?.groupId === group.groupId;
    },
  },

  created() {
    this.getAll();
  },
};
</script>
