<template>
  <div class="w-full h-full bg-gray-200 dark:bg-gray-900">
    <SideBarItem
      @selected="(r) => ((selectedFleet = r), (selectedGroup = 0))"
      @selectedGroup="(r) => (selectedGroup = r)"
      @selectedItem="(val) => setPlaqueSelected(val)"
      @closeModal="() => resetItemDetail()"
      @changeIntervention="(val) => (isIntervention = val)"
      @changeInterventionList="(val) => (interventionList = val)"
    />

    <ProductSideBarItem
      @backMenu="backProduct"
      ref="prodDetail"
      :detail="detail"
      @closeModal="() => resetItemDetail()"
      @selectedActiveDetail="(val) => (selectedActiveDetail = val)"
    />

    <FilterPanel
      :detail="filter"
      ref="filterPanel"
      @change="(r) => getFilterAll(r)"
    />

    <GroupDetailPanel
      @close="() => resetItemDetail()"
      :List="hoverList"
      v-if="hoverList.length"
      @selected="(val) => setPlaqueSelected(val)"
    />
    <detailModal :detailList="markers" />
    <div
      class="fixed right-64 z-10 md:w-4/12 xl:w-3/12 flex items-center"
      style="right: 260px; top: 128px"
    >
      <button
        @click="toggleFactories()"
        class="px-2 ml-2 h-10 w-16 rounded-lg justify-center flex items-center"
        :class="showFactories === true ? 'bg-black ' : 'bg-white'"
      >
        <img
          class="w-6 h-6"
          :src="
            showFactories === false
              ? require('@/assets/marker/factoryDark.png')
              : require('@/assets/marker/factoryLight.png')
          "
        />
      </button>
      <button
        @click="() => $modal.show('planning-state-modal')"
        class="ml-2 mr-2 bg-white rounded-md shadow-xl w-14 h-10 px-2"
      >
        <i class="fas fa-list"></i>
      </button>
      <input
        type="search"
        class="w-full h-10 px-2 border-b-2 rounded focus:outline-none bg-white"
        placeholder="Aramak için yazınız..."
        v-model="search"
        @keypress.enter="performSearchOnEnter"
      />
      <button
        @click="mapstatus()"
        class="px-2 ml-2 h-10 w-16 rounded-lg justify-center flex items-center"
        :class="$store.state.mapView == true ? 'bg-black ' : 'bg-white'"
      >
        <img
          class="w-6 h-6"
          :src="
            $store.state.mapView == false
              ? require('@/assets/marker/mapstatus.svg')
              : require('@/assets/marker/mapstatus_white.svg')
          "
        />
      </button>
      <button
        @click="() => $refs.filterPanel.showModal()"
        class="mx-2 bg-white h-10 rounded-md shadow-xl w-16"
      >
        <i class="fas fa-filter"></i>
      </button>
    </div>

    <div
      v-if="load"
      class="w-full z-20 fixed flex top-0 h-screen bg-black bg-opacity-70 items-center justify-center"
      style="left: 0%"
    >
      <i class="fas fa-spinner fa-spin fa-2x text-white"></i>
    </div>

    <GmapMap
      :max-zoom="14"
      ref="gmap"
      :center="position"
      :zoom="zoomLevel"
      :map-type-id="$store.state.mapView ? 'hybrid' : 'roadmap'"
      style="width: 100%; height: 100vh !important"
      :options="{
        minZoom: 3,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      }"
    >
      <GmapCluster
        v-if="!selectedItem"
        @click="(val) => handleClusterMouseOver(val, 'vehicle')"
      >
        <GmapMarker
          v-for="m in nonDeviationMarkers"
          :key="m.title"
          :title="m.title"
          @click="() => showItemDetail(m, 'vehicle')"
          :label="{
            text: m.title,
            color: 'white',
            className:
              'absolute bottom-4 -right-8 text-xs px-2 font-bold bg-blue-700 rounded tracking-widest',
            fontSize: '8.5px',
          }"
          :position="m.position"
          :icon="m.icon"
          rotation="30"
        >
        </GmapMarker>
        <GmapMarker
          v-for="m in deviationMarkers"
          :key="m.title"
          :title="m.title"
          @click="() => showItemDetail(m, 'vehicle')"
          :label="{
            text: m.title,
            color: 'white',
            className:
              'absolute bottom-4 -right-8 text-xs px-2 font-bold bg-red-600 rounded tracking-widest animate-pulse border-2 border-white',
            fontSize: '8.5px',
          }"
          :position="m.position"
          :icon="m.icon"
          rotation="30"
        >
        </GmapMarker>
      </GmapCluster>

      <!-- <GmapCluster
        v-if="!selectedItem"
        @click="(val) => handleClusterMouseOver(val, 'vehicle')"
        :styles="problemClusterStyles"
      >

      </GmapCluster> -->

      <GmapCluster
        v-if="!selectedItem && showFactories"
        @click="(val) => handleClusterMouseOver(val, 'factory')"
        :styles="clusterStyles"
      >
        <!-- @click="() => showItemDetail(m)" -->
        <GmapMarker
          v-for="(m, index) in vectorMarkers"
          :key="index"
          :title="m.title"
          :label="{
            text: m.title,
            color: 'white',
            className:
              'absolute bottom-4 -right-8 text-xs  px-2 text-xs font-bold bg-green-700 rounded tracking-widest',
            fontSize: '8.5px',
          }"
          :position="m.position"
          :icon="m.icon"
          rotation="30"
        >
        </GmapMarker>
      </GmapCluster>

      <!--SADECE ACTİVE MENÜ 2 DE GÖZÜKECEK-->
      <GmapMarker
        v-if="selectedItem"
        @click="() => showItemDetail(selectedItem)"
        :title="selectedItem.title"
        :label="{
          text: selectedItem.title,
          color: 'white',
          className:
            'absolute bottom-3 -right-8 text-xs  px-2 text-xs font-bold bg-black rounded',
          fontSize: '8.5px',
        }"
        :position="selectedItem.position"
        :icon="selectedItem.icon"
      >
      </GmapMarker>

      <GmapMarker
        :title="
          selectedActiveDetail.state <= 3
            ? 'Yükleme Noktası'
            : selectedActiveDetail.customer
        "
        :label="{
          text:
            selectedActiveDetail.state <= 3
              ? 'Yükleme Noktası'
              : selectedActiveDetail.customer,
          color: 'white',
          className:
            'absolute top-4 px-2 rounded left-0 text-xs  px-2 text-xs font-bold bg-red-500',
          fontSize: '12px',
        }"
        :position="selectedActiveDetail.targetPoint"
        v-if="
          selectedItem &&
          selectedActiveDetail.targetPoint.lat &&
          selectedActiveDetail.targetPoint.lng
        "
      >
      </GmapMarker>
      <GmapPolyline
        v-if="selectedActiveDetail.polyline && selectedItem"
        v-bind:path.sync="selectedActiveDetail.polyline"
        v-bind:options="{ strokeColor: '#C43A3A' }"
      >
      </GmapPolyline>
    </GmapMap>
  </div>
</template>

<script>
//Npm
import moment from "moment";

//Networking
import axios from "axios";
import { tracking } from "@/networking/urlmanager";

//Local Components
import detailModal from "./components/detail/index.vue";
import SideBarItem from "./components/sidebar/index.vue";
import ProductSideBarItem from "./components/productDetail/index.vue";
import FilterPanel from "./components/filter/index.vue";
import GroupDetailPanel from "./components/groupDetail.vue";

//Utils
import { getRotationIcon } from "../../utils/rotatingIconGenerator";

export default {
  name: "maps-page",
  components: {
    SideBarItem,
    ProductSideBarItem,
    FilterPanel,
    GroupDetailPanel,
    detailModal,
  },
  data() {
    return {
      mapview: false,
      position: { lat: 41.015137, lng: 28.97953 },
      center: { lat: 41.015137, lng: 28.97953 },
      zoomLevel: 5,
      markers: [],
      vectorMarkers: [],
      selectedGroup: "",
      originalVehicleList: [],
      selectedFleet: "",
      hoverListType: "vehicle",
      showFactories: false,
      filter: {
        region: { key: null, id: 1 },
        workStatus: 1,
        productId: null,
        productName: null,
        deliveryDate: { start: null, end: null },
        deliveryState: 1,
      },
      problemClusterStyles: [
        {
          url: require("@/assets/marker/factoryClusterRed.png"),
          height: 40,
          width: 40,
          textColor: "black",
          textSize: 14,
        },
      ],
      clusterStyles: [
        {
          url: require("@/assets/marker/factoryClusterGreen.png"),
          height: 30, // Height of the icon
          width: 30, // Width of the icon
          textColor: "black", // Text color
          textSize: 12, // Text size
        },
        {
          url: require("@/assets/marker/factoryClusterYellow.png"),
          height: 30,
          width: 30,
          textColor: "black",
          textSize: 14,
        },
        {
          url: require("@/assets/marker/factoryClusterRed.png"),
          height: 40,
          width: 40,
          textColor: "black",
          textSize: 14,
        },
        {
          url: require("@/assets/marker/factoryClusterRed.png"),
          height: 40,
          width: 40,
          textColor: "black",
          textSize: 14,
        },
        {
          url: require("@/assets/marker/factoryClusterRed.png"),
          height: 40,
          width: 40,
          textColor: "black",
          textSize: 14,
        },
      ],
      selectedActiveDetail: {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      },
      load: false,
      note: "",
      isSearch: false,
      isIntervention: false,
      cancelTokenSource: null,
      interventionList: [],
      search: "",
      detail: null,
      selectedItem: null,
      hoverList: [],
      sync: {
        date: moment().fromNow(),
        state: false,
      },
      filterLoad: true,
      isRequestPending: true,
      needInterval: true, // app.vue da setInterval için lazım silme
    };
  },
  methods: {
    backProduct() {
      this.selectedItem = null;
    },
    mapstatus() {
      this.$store.commit("toggleMapStatus");
    },

    //Plaka arama kısmı
    performSearchOnEnter() {
      // this.filterLoad=true,
      this.isSearch = true;
      this.applyFilters();
      if (this.search == "") {
        this.isSearch = false;
      }
      if (this.selectedItem) {
        this.$refs.prodDetail.hideModal();
        this.resetItemDetail();
      }
    },
    handleClusterMouseOver(cluster, type) {
      // Cluster içindeki işaretlerin isimlerini toplamak için bir dizi oluşturun
      if (typeof cluster.markers_ != "undefined") {
        this.hoverListType = type;
        this.hoverList = cluster.markers_;
      }
    },

    toggleFactories() {
      this.resetItemDetail();
      this.$refs.prodDetail.hideModal();
      this.showFactories = !this.showFactories;
    },
    showItemDetail(item, type) {
      this.selectedItem = item;
      this.selectedActiveDetail = {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      };
      this.hoverList = [];
      if (type === "vehicle") {
        this.$refs.prodDetail.showModal();
      }
    },
    showDeviated(item) {
      let noteInput;

      this.selectedActiveDetail = {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      };
      this.hoverList = [];

      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("general.warningTitle"),
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Onayla",
          cancelButtonText: "Kapat",
          customClass: {
            popup: "w-1/2",
          },
          html: `
                  <div class="flex flex-col items-center">
                  <div class="p-4">
                      <div class="bg-white rounded-lg shadow-md overflow-hidden">
                        <div class="border-b border-gray-100 p-4 flex items-center gap-2">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                          </svg>
                          <h2 class="text-lg font-semibold text-gray-900">Rotadan Çıkan Araç Bildirimi</h2>
                      </div>
                        <div class="p-6">
                          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div class="space-y-1">
                              <p class="text-sm font-medium text-gray-500">Sürücü Adı</p>
                              <p class="text-base font-semibold text-gray-900">${item.driverName}</p>
                            </div>

                            <div class="space-y-1">
                              <p class="text-sm font-medium text-gray-500">Sürücü TC</p>
                              <p class="text-base font-semibold text-gray-900">${item.driverTC}</p>
                            </div>

                            <div class="space-y-1">
                              <p class="text-sm font-medium text-gray-500">Sipariş Numarası</p>
                              <p class="text-base font-semibold text-gray-900">${item.orderNo}</p>
                            </div>

                            <div class="space-y-1">
                              <p class="text-sm font-medium text-gray-500">Araç Plaka</p>
                              <p class="text-base font-semibold text-gray-900">${item.plate}</p>
                            </div>
                          </div>
                        </div>
                    </div>
                    <input id="note" class="swal2-input w-full" placeholder="Açıklama">
                  </div>`,
          didOpen: () => {
            const popup = this.$swal.getPopup();
            noteInput = popup.querySelector("#note");
            noteInput.onkeyup = (event) =>
              event.key === "Enter" && Swal.clickConfirm();
          },
          preConfirm: () => {
            this.note = noteInput.value;
          },
        })
        .then((acc) => {
          if (acc.isConfirmed) {
            const params = {
              deviationId: item.isDeviation,
              action: this.note,
            };
            axios
              .post(tracking.updateDeviation, params, {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              })
              .then(() => {
                this.swalBox(
                  "success",
                  this.$t("general.successTitle"),
                  "",
                  false,
                  this.$t("general.OkayTitle")
                );
                this.getAll();
              })
              .catch((err) => {
                console.log(err);
              });
          }
          this.startInterval();
        });
    },
    resetItemDetail() {
      this.hoverList = [];
      this.selectedItem = null;
      this.selectedActiveDetail = {
        targetPoint: { lat: 0, lng: 0 },
        customer: "",
        polyline: [],
        state: 1,
      };
    },

    filterList() {
      if (this.isIntervention) return this.interventionList;
      else if (!this.selectedItem) return this.markers;
      else return [this.detail];
    },

    getAllVectors() {
      axios
        .get(tracking.getVectors, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.vectorMarkers = res.data.data.map((vectorItem) => ({
            ...vectorItem,
            title: vectorItem.name,
            position: {
              lat: vectorItem.latitude,
              lng: vectorItem.longtitude,
            },
            icon: {
              url: require("@/assets/marker/factoryIcon.png"),
            },
          }));
        });
    },

    async getAll(load = true) {
      this.load = load;
      try {
        const response = await axios.get(
          tracking.getAll + "?isVehicleScreen=true",
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.userData.token}`,
            },
          }
        );

        this.originalVehicleList = response.data.data;
        this.applyFilters();
        this.load = false;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isRequestPending = false;
        this.filterLoad = false;
      }
    },

    async applyFilters() {
      let vehicleList = this.originalVehicleList;
      if (this.selectedGroup === undefined) {
        this.selectedGroup = 0;
      }

      if (this.selectedFleet > 0 || this.selectedGroup > 0) {
        vehicleList = vehicleList.filter((vehicle) => {
          const hasMatchingFleet =
            this.selectedFleet === 0 || vehicle.fleetId === this.selectedFleet;

          const hasMatchingGroup =
            this.selectedGroup === 0 ||
            (Array.isArray(this.selectedGroup)
              ? this.selectedGroup.includes(vehicle.groupId)
              : vehicle.groupId === this.selectedGroup);

          return hasMatchingFleet && hasMatchingGroup;
        });
      }

      let list = this.isSearch
        ? await this.filterBySearch(vehicleList, this.search)
        : vehicleList;

      this.markers = this.filterAndMapMarkers(list);

      if (this.markers.length > 0) {
        this.updateSelectedItem();
      } else {
        this.markers = [];
      }
    },

    filterBySearch(data, search) {
      const normalizedSearch = this.normalizeText(search);
      const result = data.filter(
        (item) =>
          this.normalizeText(item.vehicle).includes(normalizedSearch) ||
          this.normalizeText(item.driverName).includes(normalizedSearch) ||
          this.normalizeText(item.orderNo) === normalizedSearch ||
          (item.orderNos &&
            item.orderNos.some(
              (orderNo) => this.normalizeText(orderNo) === normalizedSearch
            )) ||
          (item?.planning.customer &&
            this.normalizeText(item.planning.customer).includes(
              normalizedSearch
            ))
      );
      return result;
    },

    normalizeText(text) {
      return (
        text
          ?.toString()
          .toLocaleLowerCase("tr-TR")
          .replace(/[\u0300-\u036f\s]/g, "") || ""
      );
    },

    filterAndMapMarkers(list) {
      const {
        workStatus,
        productId,
        deliveryDate,
        deliveryState,
        productName,
      } = this.filter;
      const filteredList = list
        .filter(
          (el) =>
            (!this.filter.region.key ||
              this.filter.region.key.toLowerCase().trim() ===
                el.region.toLowerCase().trim()) &&
            (workStatus === 1 || workStatus - 2 === Number(el.state)) &&
            (!productId || this.productController(el.productId, productName)) &&
            (deliveryState === 1 ||
              this.isDeliveryDateInRange(el.deliveryDate, deliveryDate))
        )
        .map((el) => this.mapMarker(el));
      return filteredList;
    },

    isDeliveryDateInRange(deliveryDate, filterDate) {
      return filterDate.end && filterDate.start
        ? moment(filterDate.start).isSameOrBefore(moment(deliveryDate)) &&
            moment(filterDate.end).isSameOrAfter(moment(deliveryDate))
        : moment(filterDate.end).isSame(moment(deliveryDate));
    },

    mapMarker(el) {
      const iconUrl =
        el.speed === 0
          ? require("@/assets/marker/2.png")
          : getRotationIcon(el.direction * 60);
      return {
        ...el,
        id: el.id,
        title: el.vehicle,
        region: el.region,
        position: {
          lat: parseFloat(el.latitude),
          lng: parseFloat(el.longitude),
        },
        icon: { url: iconUrl },
        takoDate: el.driverDetail
          ? moment
              .utc(el.driverDetail.totalRunTime - 45 * 60000)
              .format("HH:mm:ss")
          : 0,
      };
    },

    updateSelectedItem() {
      const selectedItem = this.markers.find(
        (item) => item.vehicle === this.selectedItem?.vehicle
      );

      this.selectedItem = selectedItem || this.selectedItem;
    },

    handleError(error) {
      if (error.response) {
        this.errorBox(error.response);
      }
    },

    productController(val1, val2) {
      if (val1 && val2) {
        const value1 = val1
          .toLocaleLowerCase("tr-TR")
          .replace(/[\u0300-\u036f\s]/g, "");
        const value2 = val2
          .toLocaleLowerCase("tr-TR")
          .replace(/[\u0300-\u036f\s]/g, "");

        return value1.includes(value2);
      } else {
        return false;
      }
    },
    getDetail(data) {
      setTimeout(() => {
        this.position = data.position;
        this.detail = data;
      }, 50);

      this.zoomLevel = 9;
    },
    getFilterAll(val) {
      this.filter = val;
    },
    setPlaqueSelected(val) {
      const markers =
        this.hoverListType === "vehicle" ? this.markers : this.vectorMarkers;
      this.selectedItem = markers.find((r) => r.title.includes(val));
      if (this.hoverListType === "vehicle") {
        this.$refs.prodDetail.showModal();
      }
    },

    initializeCustomMapType() {
      this.customMapType = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return `https://api.tomtom.com/map/1/tile/sat/main/${zoom}/${coord.x}/${coord.y}.jpg?key=QBZW0FaE7nyqWeLuLA5PIwF93jGzSQVC`;
        },
        tileSize: new google.maps.Size(256, 256),
        maxZoom: 19,
        minZoom: 0,
        name: "Custom Satellite Overlay",
      });

      this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);

      this.map.overlayMapTypes.insertAt(0, this.customMapType);
    },

    addCustomMapType() {
      if (this.$refs.gmap && this.$refs.gmap.$mapObject) {
        this.$refs.gmap.$mapObject.mapTypes.set("custom", this.customMapType);
        this.$refs.gmap.$mapObject.setMapTypeId("custom");
      }
    },
  },
  computed: {
    deviationMarkers() {
      return this.filterList().filter((m) => m.isDeviation);
    },
    nonDeviationMarkers() {
      return this.filterList().filter((m) => !m.isDeviation);
    },
  },
  mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      this.initializeCustomMapType();
      this.addCustomMapType();
    });
  },
  created() {
    this.getAll();
    this.getAllVectors();
  },
  watch: {
    selectedItem(val) {
      if (!val) return;
      this.getDetail(val);
    },
    selectedFleet() {
      this.applyFilters();
    },
    selectedGroup() {
      this.applyFilters();
    },
    search(val) {
      if (val.length == 0) this.performSearchOnEnter();
    },
  },
};
</script>
<style>
.gm-style
  > div
  > div
  > div
  > div
  > div
  > div
  > div[style*="color: rgb(254, 254, 254)"] {
  background: blue;
  border-radius: 50%;
  line-height: 20px;
  height: 20px;
  width: 20px;
  font-size: 12px !important;
  text-align: center;
  font-weight: 700;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.2);
}
</style>
